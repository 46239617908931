import React from "react";
import { useState, useEffect } from "react";
import { DateTime } from "luxon";
import "./sessionsFormModule.scss";

export function SessionContainer({ sessionClick, active, sess }) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  useEffect(() => {
    setStartDate(DateTime.fromISO(sess.startDate));
    setEndDate(sess.endDate !== "" ? DateTime.fromISO(sess.endDate) : "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`summer-camp-register-sessions-form-module-session 
                  ${active ? "selected" : ""}`}
      key={sess._id}
      onClick={() => {
        sessionClick(sess);
      }}
    >
      <div className="summer-camp-register-sessions-form-module-session-text-container">
        {startDate && (
          <p className="summer-camp-register-sessions-form-module-session-text">
            {startDate.toFormat("LLL dd")}
            {endDate !== "" ? ` - ${endDate.toFormat("LLL dd")}` : ""}
            <span
              className={
                sess.etcDetail === " - WAITLIST"
                  ? "orange"
                  : sess.etcDetail === " - COMPLETELY FULL"
                  ? "red"
                  : ""
              }
            >
              {sess.etcDetail}
            </span>
          </p>
        )}
        <p className="summer-camp-register-sessions-form-module-session-text">
          ${sess.price}
        </p>
      </div>
    </div>
  );
}
