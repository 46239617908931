import React, { useEffect } from "react";
import Portal from "../../../../components/Portal/Portal";
import './modal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

export default function Modal({ children, isOpen, handleClose}) {
  useEffect(() => {
    const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [handleClose]);

  if (!isOpen) return null;

  return (
    <Portal wrapperId="Portal-Modal-Container">
        <div className="Modal">
            <div className="Modal-contentWrapper">
                <button onClick={handleClose} className="Modal-closeButton">
                    <FontAwesomeIcon icon={faX} className="Modal-closeIcon" />
                </button>
                <div className="Modal-content">{children}</div>
            </div>
        </div>
    </Portal>
  );
}
