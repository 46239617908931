import { useNavigate } from "react-router-dom";

const Dropdown = ({ submenus, dropdown, setDropdown, mobileDropdowns, setMobileDropdowns, setOpen, index, mobile }) => {
  const navigate = useNavigate();
  let itemClick = (submenu) => {
    setOpen(false);
    navigate(submenu.page);
    if(mobile){
      let items = [...mobileDropdowns];
      for(var i = 0; i < mobileDropdowns.length; i++){
        items[i] = false;
      }
      setMobileDropdowns(items);
    }
  }

  return (
    <ul className={`dropdown ${dropdown ? "show" : ""} ${mobile && mobileDropdowns[index] ? "showMobile" : ""}`}
      onMouseOver={() => setDropdown(true)}
      onMouseLeave={() => setDropdown(false)}>
     {submenus.map((submenu, index) => (
      <div className="menu-items-dropdown-item" onClick={()=>itemClick(submenu)} key={index}>
        <a className="dropdown-items-item-name"
          href={submenu.page}>
          {submenu.title}
        </a>
      </div>
     ))}
    </ul>
   );
 };
 
 export default Dropdown;