import React, { useEffect, useState } from "react";
import MenuItems from "./MenuItems";
import { menuItems } from "./items";
import "./navbar.scss";
import Logo from "../../assets/Logo_Black.svg";
import { useNavigate } from "react-router-dom";

export function Navbar({ open, setOpen }) {
  const navigate = useNavigate();
  const [mobileDropdowns, setMobileDropdowns] = useState([false, false, false]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1000) {
        setOpen(false);
      }
    })
  }, [setOpen])

  const setActive = () => {
    setOpen(!open);
  }

  const itemClick = (page) => {
    setOpen(false);
    navigate(page);
  }

  return (
    <div className="navContainer" id="nav">
      <div className="navigation-margin-container">
        <div className="menuContainer">
          <div className={`leftSideButtons ${open ? 'active' : 'closed'}`}>
            {menuItems.map((menu, index) => {
              return (
                  <MenuItems items={menu} key={index} mobile={false} open={open} setOpen={setOpen}/>
                );
            })}
          </div>
          <div className="menuItemsContainer">
            <img className="navLogo" alt="Camp Sarraka Logo" src={Logo}
              onClick={() => navigate("/")}/>
          </div>
          <div className={`rightSideButtons ${open ? 'active' : 'closed'}`}>
            <li className="menu-items">
              <div className="menu-items-menu-item" onClick={() => navigate("/programs/registration")}>
                <button className="menu-items-item-name">Sign Up</button>
              </div>
              <div className="menu-items-menu-item" onClick={() => navigate("/sarrakaships")}>
                <button className="menu-items-item-name">Give Back</button>
              </div>
              {/* <div className="menu-items-menu-item" onClick={() => navigate("/sarrakaships")}>
                <button className="menu-items-item-name">Español</button>
              </div> */}
            </li>
          </div>
          <div className={`mobileNav ${open ? 'active' : 'closed'}`}>
            <div className="mobileNav-content">
              {menuItems.map((menu, index) => {
                return (
                  <MenuItems items={menu} key={index} index={index} mobile={true} open={open} setOpen={setOpen} 
                    mobileDropdowns={mobileDropdowns}
                    setMobileDropdowns={setMobileDropdowns}/>
                  );
              })}
              <li className="menu-items mobileMenuButtons">
                <div className="menu-item-button" onClick={() => itemClick("/programs/registration")}>
                  <button className="menu-item-button-text">Sign Up</button>
                </div>
                <div className="menu-item-button" onClick={() => itemClick("/sarrakaships")}>
                  <button className="menu-item-button-text">Give Back</button>
                </div>
              </li>
            </div>
          </div>
          <div className={`hamburger-container ${open ? "open" : ""}`} onClick={setActive}>
            <div className="hamburger"></div>
          </div>
        </div>
      </div>
    </div>
  )

}