import React from "react";
import "./FAQ.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

export function FAQ({ faq, index, toggleFAQ }) {
    return (
        <div className={"FAQ " + (faq.open ? 'FAQ-open' : '')} key={index} onClick={() => toggleFAQ(index)}>
            <div className="FAQ-question">
                <span className="FAQ-questionText">{faq.question}</span>
                <FontAwesomeIcon icon={faPlus} className="FAQ-faqIcon FAQ-faqIcon--plus" />
                <FontAwesomeIcon icon={faMinus} className="FAQ-faqIcon FAQ-faqIcon--minus" />
            </div>
            <div className="FAQ-answer">
                {faq.answer}
            </div>
        </div>
    );
}