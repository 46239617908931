import React from "react";
import "./summerCampRegisterPage.scss";
import { Link } from "react-router-dom";

export function SummerCampRegisterPage() {
  return (
    <div className="AfterSchoolRegister" id="formTop">
      <div className="AfterSchoolRegister-header-container">
        <div className="AfterSchoolRegister-textWrapper">
          <h3 className="AfterSchoolRegister-header">
            Camp Sarraka Summer 2024
          </h3>
          <div className="AfterSchoolRegister-subHeader">
            <Link
              to="../summercamp/register"
              reloadDocument
              className="info-section-button-link"
            >
              Sign Up Now
            </Link>
          </div>
        </div>
      </div>
      <img
        className="after-school-register-container-background"
        alt="background"
      />
      <div className="AfterSchoolRegister-content">
        <div className="AfterSchoolRegister-section">
          <div className="AfterSchoolRegister-sectionHeader">Details</div>

          <div className="AfterSchoolRegister-sectionContent">
            <div className="AfterSchoolRegister-price">
              <span className="AfterSchoolRegister-miniHeader">Time:</span> 9 am
              - 4 pm, Before-care: 8:00 am - 9:00 am, After-care: 4:00 pm - 5:30
              pm
            </div>
            <div className="AfterSchoolRegister-time">
              <span className="AfterSchoolRegister-miniHeader">Price:</span>{" "}
              $460 per week, Before-care: $50 per week, After-care: $60 per week
            </div>
            <div className="AfterSchoolRegister-price">
              <span className="AfterSchoolRegister-miniHeader">Location:</span>{" "}
              Unitarian Universalist Church of Arlington, VA (4444 Arlington
              Blvd, Arlington VA 22204)
            </div>
            <div className="AfterSchoolRegister-extra">
              <span className="AfterSchoolRegister-smallHeader">
                Financial Aid:
              </span>{" "}
              We want all kids to have an opportunity for a fun and rewarding
              summer experience. If you are interested in financial aid, please
              select the box presented at the end of registration. Full and
              partial scholarship options are available.
            </div>
          </div>
        </div>

        <div className="AfterSchoolRegister-section">
          <div className="AfterSchoolRegister-sectionHeader">Schedule</div>
          <div className="AfterSchoolRegister-scheduleContent">
            <table className="AfterSchoolRegister-scheduleTable">
              <tr className="AfterSchoolRegister-scheduleTable-titlerow">
                <th colspan="2">Schedule</th>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 1</td>
                <td>June 17 - June 21 (no camp June 19)</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 2</td>
                <td>June 24 - June 28</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 3</td>
                <td>July 1 - July 5 (no camp July 4)</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 4</td>
                <td>July 8 - July 12</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 5</td>
                <td>July 15 - July 19</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 6</td>
                <td>July 22 - July 26</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 7</td>
                <td>July 29 - August 2</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 8</td>
                <td>August 5 - August 9</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 9</td>
                <td>August 12 - August 16</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 10</td>
                <td>August 19 - August 23</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
