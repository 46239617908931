import React from "react";
import { Link } from "react-router-dom";
import "./sarrakaships.scss";

export function Sarrakaships() {
  return (
    <div>
      <div className="SarrakashipsHome">
          <div className="SarrakashipsHome-content">
            <div className="SarrakashipsHome-leftContent">
              <div className="SarrakashipsHome-subTitle">
                Help Fund <br />a Sarrakaship
              </div>
            </div>
            <div className="SarrakashipsHome-rightContent">
              <div className="SarrakashipsHome-text">
                Every kid deserves the chance to grow, thrive, and have fun. That’s why we are so excited to work with 1st Road South, a 501(c)3 nonprofit organization, to provide scholarships - or “Sarrakaships” as we call them - for kids who cannot afford to attend summer camp or after-school programs.
                <br />
                <br />
                Help us make sure every kid has a chance to reach their full potential.
              </div>

              <div className="SarrakashipsHome-buttonWrapper">
                <Link to="../sarrakaships" reloadDocument className="SarrakashipsHome-button">Learn More</Link>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}