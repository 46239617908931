export const sessions = [
  {
    _id: "2023-09-25T04:00:00.000Z",
    location: "",
    startDate: "2023-09-25T04:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-10-09T04:00:00.000Z",
    location: "",
    startDate: "2023-10-09T04:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-10-20T04:00:00.000Z",
    location: "",
    startDate: "2023-10-20T04:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-11-07T06:00:00.000Z",
    location: "",
    startDate: "2023-11-07T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: " - WAITLIST",
  },
  {
    _id: "2023-11-10T06:00:00.000Z",
    location: "",
    startDate: "2023-11-10T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-12-21T06:00:00.000Z",
    location: "",
    startDate: "2023-12-21T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: " - WAITLIST",
  },
  {
    _id: "2023-12-22T06:00:00.000Z",
    location: "",
    startDate: "2023-12-22T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: " - WAITLIST",
  },
  {
    _id: "2023-12-27T06:00:00.000Z",
    location: "",
    startDate: "2023-12-27T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-12-28T06:00:00.000Z",
    location: "",
    startDate: "2023-12-28T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2024-01-15T06:00:00.000Z",
    location: "",
    startDate: "2024-01-15T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2024-01-29T06:00:00.000Z",
    location: "",
    startDate: "2024-01-29T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2024-02-16T06:00:00.000Z",
    location: "",
    startDate: "2024-02-16T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2024-02-19T06:00:00.000Z",
    location: "",
    startDate: "2024-02-19T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2024-03-05T06:00:00.000Z",
    location: "",
    startDate: "2024-03-05T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: " - COMPLETELY FULL",
  },
  {
    _id: "2024-03-15T06:00:00.000Z",
    location: "",
    startDate: "2024-03-15T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: " - COMPLETELY FULL",
  },
  {
    _id: "2024-03-25T06:00:00.000Z",
    location: "",
    startDate: "2024-03-25T06:00:00.000Z",
    endDate: "2024-03-29T06:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 435,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2024-04-10T06:00:00.000Z",
    location: "",
    startDate: "2024-04-10T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: " - WAITLIST",
  },
  {
    _id: "2024-04-15T06:00:00.000Z",
    location: "",
    startDate: "2024-04-15T06:00:00.000Z",
    endDate: "",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 100,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: " - WAITLIST",
  },
];

export const sessionCost = {
  "2023-09-25T04:00:00.000Z": 100.0,
  "2023-10-09T04:00:00.000Z": 100.0,
  "2023-10-20T04:00:00.000Z": 100.0,
  "2023-11-07T06:00:00.000Z": 100.0,
  "2023-11-10T06:00:00.000Z": 100.0,
  "2023-12-21T06:00:00.000Z": 100.0,
  "2023-12-22T06:00:00.000Z": 100.0,
  "2023-12-27T06:00:00.000Z": 100.0,
  "2023-12-28T06:00:00.000Z": 100.0,
  "2024-01-15T06:00:00.000Z": 100.0,
  "2024-01-29T06:00:00.000Z": 100.0,
  "2024-02-16T06:00:00.000Z": 100.0,
  "2024-02-19T06:00:00.000Z": 100.0,
  "2024-03-05T06:00:00.000Z": 100.0,
  "2024-03-15T06:00:00.000Z": 100.0,
  "2024-03-25T06:00:00.000Z": 435.0,
  "2024-04-10T06:00:00.000Z": 100.0,
  "2024-04-15T06:00:00.000Z": 100.0,
};
