import React from "react";
import "./schoolYearCampsPage.scss";
import { Link } from "react-router-dom";

export function SchoolYearCampsPage() {
  return (
    <div className="AfterSchoolRegister" id="formTop">
      <div className="AfterSchoolRegister-header-container">
        <div className="AfterSchoolRegister-textWrapper">
          <h3 className="AfterSchoolRegister-header">
            School Year Camp Sarraka
          </h3>
          <div className="AfterSchoolRegister-subHeader">
            <a
              href="https://campsarraka.campbrainregistration.com"
              target="_blank"
              reloadDocument
              rel="noreferrer"
              className="info-section-button-link"
            >
              Sign Up Now
            </a>
          </div>
        </div>
      </div>
      <img
        className="after-school-register-container-background"
        alt="background"
      />
      <div className="AfterSchoolRegister-content">
        <div className="AfterSchoolRegister-section">
          <div className="AfterSchoolRegister-sectionHeader">Details</div>

          <div className="AfterSchoolRegister-sectionContent">
            <div className="AfterSchoolRegister-price">
              <span className="AfterSchoolRegister-miniHeader">Time:</span> 9 am
              - 4 pm, Before-care: 8:00 am - 9 am, After-care: 4 pm - 5:30 pm
            </div>
            <div className="AfterSchoolRegister-time">
              <span className="AfterSchoolRegister-miniHeader">Price:</span>{" "}
              $120 per day, Before-care & After-care: $15 each
            </div>
            <div className="AfterSchoolRegister-price">
              <span className="AfterSchoolRegister-miniHeader">Location:</span>{" "}
              Unitarian Universalist Church of Arlington, VA (4444 Arlington
              Blvd, Arlington VA 22204)
            </div>
            <div className="AfterSchoolRegister-extra">
              <span className="AfterSchoolRegister-smallHeader">
                Financial Aid:
              </span>{" "}
              We want all kids to have an opportunity for a fun and rewarding
              after-school experience. Please don't hesitate to reach out to
              ethan@sarraka.com for questions about financial aid and check our
              financial aid interest box while signing up. Full and partial
              scholarship options are available.
            </div>
            <div className="AfterSchoolRegister-extra">
              <span className="AfterSchoolRegister-smallHeader">
                Age Groups:
              </span>{" "}
              <ul>
                <li>
                  <span className="AfterSchoolRegister-underline">
                    Polar Bears
                  </span>{" "}
                  aka Camp Sarraka Jr. (5 and 6-year-olds)
                </li>
                <li>
                  <span className="AfterSchoolRegister-underline">
                    Snow Leopards
                  </span>{" "}
                  (7 to 9-year-olds)
                </li>
                <li>
                  <span className="AfterSchoolRegister-underline">
                    Arctic Wolves
                  </span>
                  (10 to 13-year-olds)
                </li>
              </ul>
              Each group will have 35 available spots per camp day.
              Unfortunately, we are unable to accept 4-year-olds.
            </div>
          </div>
        </div>

        <div className="AfterSchoolRegister-section">
          <div className="AfterSchoolRegister-sectionHeader">
            Registration Process
          </div>

          <div className="AfterSchoolRegister-sectionContent">
            <div className="AfterSchoolRegister-price">
              <span className="AfterSchoolRegister-miniHeader">
                Initial Sign-Up Window:
              </span>{" "}
              <span className="AfterSchoolRegister-underline">
                August 28 @ 6pm
              </span>{" "}
              through{" "}
              <span className="AfterSchoolRegister-underline">September 4</span>
              . During this week, all fall camp days will show as "
              <span className="AfterSchoolRegister-smallHeader">
                waitlisted.
              </span>
              " Even though camp days will show as waitlisted, please sign-up
              for all days you are interested in enrolling.
              <br />
              <br />
              Registration for winter camps will open{" "}
              <span className="AfterSchoolRegister-underline">
                October 23
              </span>{" "}
              and registration for spring camps will open{" "}
              <span className="AfterSchoolRegister-underline">January 14</span>.
            </div>
            <div className="AfterSchoolRegister-price">
              <span className="AfterSchoolRegister-miniHeader">
                Enrollment Status Notification:
              </span>{" "}
              <span className="AfterSchoolRegister-underline">
                Week of September 9
              </span>
              <br />
              <br />
              We are extremely grateful to be meeting so many amazing new
              campers and growing the Camp Sarraka family. With limited
              available spots, our goal is to make sure every camper has an
              opportunity to attend camp this school year.
              <br />
              <br />
              We will notify everyone the{" "}
              <span className="AfterSchoolRegister-underline">
                week of September 9
              </span>{" "}
              with their enrollment status – accepted or waitlisted – for each
              selected fall camp day.
              <br />
              <br />
              We will do our best to make sure everyone can attend for as many
              days as possible, but we unfortunately cannot guarantee everyone
              will be enrolled for any or all of the days they originally
              selected.
            </div>
          </div>
        </div>

        <div className="AfterSchoolRegister-sectionContent">
          <div className="AfterSchoolRegister-sectionHeader">
            New Registration Software
          </div>
          <br />
          We are excited to announce that we will be using a new camp
          registration software. After making an account, you may log in to see
          your family paperwork, payment information, and active registration at
          any time. Please don’t hesitate to reach out to Ethan@Sarraka.com if
          you have any questions at all.
        </div>

        <div className="AfterSchoolRegister-section">
          <div className="AfterSchoolRegister-sectionHeader">Schedule</div>
          <div className="AfterSchoolRegister-scheduleContent">
            <table className="AfterSchoolRegister-scheduleTable">
              <tr className="AfterSchoolRegister-scheduleTable-titlerow">
                <th colspan="2">Fall Camps</th>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>October 3</td>
                <td>Religious Holiday</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>October 11</td>
                <td>Parent-Teacher Conferences</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>October 14</td>
                <td>Indigenous Peoples Day</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>November 1</td>
                <td>Religious Holiday</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>November 4</td>
                <td>No School</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>November 5</td>
                <td>Election Day</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>November 11</td>
                <td>Veterans Day</td>
              </tr>
            </table>
          </div>
          <div className="AfterSchoolRegister-scheduleContent">
            <table className="AfterSchoolRegister-scheduleTable">
              <tr className="AfterSchoolRegister-scheduleTable-titlerow">
                <th colspan="2">Winter Camps</th>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>December 23</td>
                <td>Winter Break</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>December 27</td>
                <td>Winter Break</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>December 30</td>
                <td>Winter Break</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>January 3</td>
                <td>Winter Break</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>January 20</td>
                <td>MLK Day</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>January 31</td>
                <td>No School</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>February 17</td>
                <td>Presidents Day</td>
              </tr>
            </table>
          </div>
          <div className="AfterSchoolRegister-scheduleContent">
            <table className="AfterSchoolRegister-scheduleTable">
              <tr className="AfterSchoolRegister-scheduleTable-titlerow">
                <th colspan="2">Spring Camps</th>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>March 14</td>
                <td>Parent-Teacher Conferences</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>October 11</td>
                <td>Parent-Teacher Conferences</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>March 31</td>
                <td>Religious Holiday</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>April 14-18</td>
                <td>Spring Break</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>April 21</td>
                <td>No School</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
