import React from "react";
import "./testimonials.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

import { testimonials } from "./testimonials";


export function TestimonialsSection(){

  return (
    <div className="testimonialsContainer">
      <div className="container">
        <div className="testimonialsSwiperContainer">
          <Swiper
          cssMode={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          loop={true}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          modules={[Navigation, Pagination, Autoplay]}
          className="testimonialSwiper">
            {testimonials.map((testimonial, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="testimonialTextSection">
                    <p className="testimonialText">"{testimonial.text}"</p>
                    <span className="testimonialAuthor">{testimonial.author}</span>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}