import React from "react";
import { Link } from "react-router-dom";
import "./infoSection.scss";

export function InfoSection({ image1, image2, text } ) {
  return (
    <div style={{ width: "100%" }}>
      <div className="infoSectionContainer2">
        <div className="container">
          <div className="twoColumnContainer">
            <div className="columnSection">
              <div className="imagesContainer">
                <div className="imageItemTop">
                  <img className="image image--one" src={image1} alt="campers having fun" />
                </div>
                <div className="imageItemBottom">
                  <img className="image" src={image2} alt="Campers having fun"/>
                </div>
              </div>
            </div>
            <div className="columnSection">
              <div className="textSection">
                <div className="sectionText">{ text }</div>
                <div className="info-section-button">
                  <Link to="../about" reloadDocument className="info-section-button-link">Learn More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}