import React from "react";
import "./schoolYearCampsRegistration.scss";
import { RegistrationForm } from "../../design-system/components/RegistrationForm/RegistrationForm";
import { sessions } from "./SchoolYearCampsSessions";

export function SchoolYearCampsRegistration() {
  return (
    <div className="SchoolYearCampRegister">
      <img
        className="summer-camp-register-container-background"
        alt="background"
      />

      <div className="SummerCampRegister-section">
        <RegistrationForm sessions={sessions} schoolyear={true} />
      </div>
    </div>
  );
}
