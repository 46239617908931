import React from "react";
import "./registrationForm.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ParentForm } from "./components/ParentForm/ParentForm";
import { SessionForm } from "./components/SessionForm/SessionForm";
import { DateTime } from "luxon";
import Modal from "./components/Modal/Modal";

export function RegistrationForm({
  sessions,
  grade,
  teacher,
  cit,
  summercamp,
  schoolyear,
  afterSchool,
}) {
  const navigate = useNavigate();
  const [isOpenFail, setIsOpenFail] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  var [finalData, setFinalData] = useState({
    address: {
      lineOne: "",
      lineTwo: "",
      city: "",
      state: "",
      postalCode: "",
    },
    emergencyContacts: [
      {
        firstName: "",
        lastName: "",
        phoneNumber: "",
      },
      {
        firstName: "",
        lastName: "",
        phoneNumber: "",
      },
    ],
    parents: [
      {
        primary: true,
        firstName: "",
        lastName: "",
        email: "",
        cellPhone: "",
        maritalStatus: "",
        relationship: "",
      },
      {
        primary: false,
        firstName: "",
        lastName: "",
        email: "",
        cellPhone: "",
        title: "",
      },
    ],
    campers: [
      {
        firstName: "",
        lastName: "",
        gender: "",
        birthday: "",
        grade: "",
        shirtSize: "",
        specialConsiderations: "",
        sessions: [],
      },
    ],
    financialAssistance: false,
  });

  const closeSuccessModal = () => {
    navigate("/");
  };

  const finalSubmit = () => {
    // Final Submit
    let data = finalData;
    for (let i = 0; i < data["campers"].length; i++) {
      let sessionArr = [];
      data["campers"][i]["birthday"] = DateTime.fromFormat(
        data["campers"][i]["birthday"],
        "MM/dd/yyyy"
      ).toISODate();
      for (let key in data["campers"][i]["sessions"]) {
        let sessionDict = {};
        sessionDict["sessionId"] = key;
        sessionDict["morningExtended"] = [];
        sessionDict["afternoonExtended"] = [];
        sessionArr.push(sessionDict);
      }
      data["campers"][i]["sessions"] = sessionArr;
    }

    setFinalData(data);

    fetch("/api/session/signupemail", {
      method: "POST",
      mode: "cors",
      headers: cit
        ? { "Content-Type": "application/json", cit: true }
        : schoolyear
        ? { "Content-Type": "application/json", schoolYear: true }
        : afterSchool
        ? { "Content-Type": "application/json", afterSchool: true }
        : { "Content-Type": "application/json" },
      body: JSON.stringify(finalData), // body data type must match "Content-Type" header
    }).then((response) => {
      if (response.status >= 400) {
        setIsOpenFail(true);
      } else if (response.status >= 200 && response.status < 300) {
        setIsOpenSuccess(true);
      } else {
        setIsOpenFail(true);
      }

      setIsLoading(false);
    });
  };

  const [step, setStep] = useState(0);
  const [cost, setCost] = useState(0.0);

  return (
    <div className="RegistrationForm">
      <div className="form-manager-content-container">
        {step === 0 ? (
          <ParentForm
            step={step}
            setStep={setStep}
            finalData={finalData}
            setFinalData={setFinalData}
          />
        ) : (
          <SessionForm
            step={step}
            setStep={setStep}
            setCost={setCost}
            cost={cost}
            finalSubmit={finalSubmit}
            finalData={finalData}
            setFinalData={setFinalData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            sessions={sessions}
            finass={true}
            type={"Camper"}
            grade={grade}
            teacher={teacher}
            summercamp={summercamp}
            afterSchool={afterSchool}
          />
        )}
      </div>
      <Modal handleClose={() => setIsOpenFail(false)} isOpen={isOpenFail}>
        <div className="FailModal">
          <div className="FailModal-contentWrapper">
            <div className="FailModal-text">
              Hm, something went wrong.
              <br />
              <br />
              Please wait a few minutes and try again, and if your sign up still
              does not work, please email us at{" "}
              <a className="FailModal-link" href="mailto:jake@sarraka.com">
                ethan@sarraka.com
              </a>{" "}
              so we can complete your registration for you!
            </div>
            <div className="FailModal-buttonWrapper">
              <button
                className="FailModal-button"
                onClick={() => setIsOpenFail(false)}
              >
                Return to Form
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        handleClose={() => closeSuccessModal(false)}
        isOpen={isOpenSuccess}
      >
        <div className="SuccessModal">
          <div className="SuccessModal-contentWrapper">
            {summercamp ? (
              <div className="SuccessModal-text">
                Thank you so much for signing up for Camp Sarraka summer 2024!
                <br />
                <br />
                In the next 15 minutes, you should receive an automated email
                confirming your information and registered weeks.
                <br />
                <br />
                In the next 24 to 48 hours, we will reach out over email to
                confirm your registered weeks and registration total. We will
                also send along required summer forms, deposit information, and
                payment options, so you can officially reserve your summer spot.
                Thank you so much. We can't wait for you to join us.
              </div>
            ) : afterSchool ? (
              <div className="SuccessModal-text">
                Thank you so much for your interest in Camp Sarraka’s Barcroft
                After-School Enrichment! Spring session registration will be
                carried out via <u>lottery system</u>. Enrollment is open from
                <b> March 14 to March 22</b>, and all families will be notified
                by <b>April 1</b> about their enrollment status!
              </div>
            ) : (
              <div className="SuccessModal-text">
                Thank you so much for your interest in Camp Sarraka! We will
                reach out over email about your registered off-day(s) —
                available spots, general camp information, required forms, &
                payment options.
              </div>
            )}
            <div className="SuccessModal-buttonWrapper">
              <a className="SuccessModal-button" href="/">
                Return to Home
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
