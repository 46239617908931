import React from "react";
import "./contact.scss";
import facebook from "../../assets/facebookLogo.png";
import youtube from "../../assets/youtubeLogo.svg";
import instagram from "../../assets/instagramLogo.png";
import tiktok from "../../assets/tiktokLogo.svg";

export function Contact() {
    return (
        <div className="Contact">
          <div className="Contact-header-container">
                <div className="Contact-textWrapper">
                    <h3 className="Contact-header">Contact Information</h3>
                </div>
            </div>
          <img className="Contact-background" alt="background"/>
          <div className="Contact-section">
              <div className="Contact-sectionBody">
                <div className="Contact-info">
                  <div className="Contact-personInfo">
                    <div className="Contact-infoSubHeader">Co-Founder: Jake Karton</div>
                    <a className="Contact-info-text" href="mailto:jake@sarraka.com">jake@sarraka.com</a> <span className="Contact-separator"> | </span><a className="Contact-info-text" href="tel:7036229210">(703) 622-9210</a>
                  </div>
                  <div className="Contact-personInfo">
                    <div className="Contact-infoSubHeader">Co-Founder: Brody Karton</div>
                    <a className="Contact-info-text" href="mailto:brody@sarraka.com">brody@sarraka.com</a> <span className="Contact-separator"> | </span><a className="Contact-info-text" href="tel:7036229210">(703) 622-9108</a>
                  </div>
                  <div className="Contact-personInfo">
                    <div className="Contact-infoSubHeader">Director: Ethan Heckler</div>
                    <a className="Contact-info-text" href="mailto:ethan@sarraka.com">ethan@sarraka.com</a> <span className="Contact-separator"> | </span><a className="Contact-info-text" href="tel:7036229210">(703) 409-9604</a>
                  </div>
                </div>
                
                <div className="Contact-social">
                  <div className="Contact-social-column">
                    <a className="Contact-social-anchor" href="https://www.instagram.com/campsarraka/">
                      <img className="Contact-social-anchor-logo" src={instagram} alt="instagram logo"/>
                      <p className="Contact-social-anchor-text">@CampSarraka</p>
                    </a>
                    <a className="Contact-social-anchor" href="https://www.youtube.com/@campsarraka">
                      <img className="Contact-social-anchor-logo" src={youtube} alt="youtube logo"/>
                      <p className="Contact-social-anchor-text">@CampSarraka</p>
                    </a>
                  </div>
                  <div className="Contact-social-column">
                    <a className="Contact-social-anchor" href="https://www.facebook.com/people/Camp-Sarraka/100089114388539/?mibextid=LQQJ4d">
                      <img className="Contact-social-anchor-logo" src={facebook} alt="facebook logo"/>
                      <p className="Contact-social-anchor-text">@CampSarraka</p>
                    </a>
                    <a className="Contact-social-anchor" href="https://www.tiktok.com">
                      <img className="Contact-social-anchor-logo" src={tiktok} alt="tiktok logo"/>
                      <p className="Contact-social-anchor-text">@CampSarraka</p>
                    </a>
                  </div>
                </div> 
              </div>
          </div>
        </div>
    );
}