import React, { useState } from "react";
import "./summerCampAbout.scss";
import { FAQ } from "./components/FAQ.js";

export function SummerCampFAQs() {
  const [faqs, setfaqs] = useState([
    {
      question: "How did you choose the name Camp Sarraka?",
      answer:
        "The name Sarraka comes from a magical world filled with creativity and adventure that Jake and Brody created as kids. It’s a place where kids are free to play, explore, be creative, and have fun.",
      open: false,
    },
    {
      question: "Where are pick-up and drop-off for Camp Sarraka?",
      answer:
        "Pick-up and drop-off are at the Unitarian Universalist Church of Arlington, VA. The address is 4444 Arlington Blvd, Arlington VA 22204.",
      open: false,
    },
    {
      question: "What are the official hours for Camp Sarraka?",
      answer: "Official hours are 9 a.m. - 4 p.m.",
      open: false,
    },
    {
      question: "Is there before-care and after-care?",
      answer:
        "Yes! Before-care runs from 8:00 am - 9:00 am and after-care runs from 4:00 pm - 5:30 pm. Each session is $50 per week for before-care and $60 per week for after-care. Before and after-care are located at the Unitarian Universalist Church of Arlington, VA (4444 Arlington Blvd, Arlington VA 22204) where camp is also held.",
      open: false,
    },
    {
      question: "Are half-day options available?",
      answer:
        "Unfortunately, we do not have any half-day options available this summer. We are very sorry for the inconvenience. ",
      open: false,
    },
    {
      question: "What is the cost of Camp Sarraka?",
      answer:
        "The price of Camp Sarraka is $460 per week, which includes a Camp Sarraka t-shirt, craft projects, and a signed copy of The Last Akaway, an award-winning spirit animal adventure book for middle grade readers.",
      open: false,
    },
    {
      question: "Are financial aid options available?",
      answer:
        "Yes, we want all kids to have an opportunity for a rewarding and fun summer experience. If you are interested in financial aid, please check the financial aid box presented during the registration process and we will contact you directly.",
      open: false,
    },
    {
      question: "What is the age-range for the camp?",
      answer:
        "Camper ages range from 5 to 12 (kindergarten to 7th grade). Many of our campers have gone on to be Camp Sarraka counselors in training (CITs) in 8th grade and counselors as high school students.",
      open: false,
    },
    {
      question: "How do I become a CIT (Counselor in Training) or Counselor?",
      answer:
        "If you are interested in becoming a CIT or a counselor, please reach out to Jake (jake@sarraka.com)!",
      open: false,
    },
    {
      question: "¿Hablas español?",
      answer:
        "¡Si, hablamos español! Si necesitas información en español, envía un mensaje a Ethan (Ethan@sarraka.com) o Brody (Brody@sarraka.com). Ethan y Brody tomaron cursos de alto nivel en la escuela secundaria de Wakefield y en la Universidad. Ethan completó sus estudios en la ciudad de Valencia, España. Brody completó sus estudios en la ciudad de Buenos Aires, Argentina.",
      open: false,
    },
    {
      question: "What should my camper bring each day?",
      answer:
        "Campers should bring a water bottle, lunch, sunscreen and/or hat, and a nut-free snack. ",
      open: false,
    },
    {
      question: "Should my camper bring a cell phone?",
      answer:
        "Please no cell phones or other electronic devices. Your camper will be having so much fun they won’t even miss ‘em.",
      open: false,
    },
    {
      question:
        "What if I need to reach my camper or someone else at the camp onsite?",
      answer:
        "You will receive an emergency contact number prior to the first day of camp. In addition, if you have any questions or concerns during the camp day or anytime, you can contact Jake (703-622-9210), Brody (703-622-9108), or Ethan (703-409-9604).",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="SummerCampAbout">
      <div className="SummerCampAbout-header-container">
        <div className="SummerCampAbout-textWrapper">
          <h3 className="SummerCampAbout-header">Frequently Asked Questions</h3>
        </div>
      </div>
      <img className="SummerCampAbout-background" alt="background" />

      <div className="SummerCampAbout-content">
        <div className="SummerCampAbout-sectionContent">
          {faqs.map((faq, i) => (
            <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>
    </div>
  );
}
