export const sessions = [
  {
    _id: "2023-06-17T04:00:00.000Z",
    location: "",
    startDate: "2023-06-17T04:00:00.000Z",
    endDate: "2023-06-21T04:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 200,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: " (no camp 6/19)",
  },
  {
    _id: "2023-06-24T04:00:00.000Z",
    location: "",
    startDate: "2023-06-24T04:00:00.000Z",
    endDate: "2023-06-28T04:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 250,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-07-01T04:00:00.000Z",
    location: "",
    startDate: "2023-07-01T04:00:00.000Z",
    endDate: "2023-07-05T04:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 200,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: " (no camp 7/4)",
  },
  {
    _id: "2023-07-08T04:00:00.000Z",
    location: "",
    startDate: "2023-07-08T04:00:00.000Z",
    endDate: "2023-07-12T04:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 250,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-07-15T04:00:00.000Z",
    location: "",
    startDate: "2023-07-15T04:00:00.000Z",
    endDate: "2023-07-19T04:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 250,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-07-22T04:00:00.000Z",
    location: "",
    startDate: "2023-07-22T04:00:00.000Z",
    endDate: "2023-07-26T04:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 250,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-07-29T04:00:00.000Z",
    location: "",
    startDate: "2023-07-29T04:00:00.000Z",
    endDate: "2023-08-02T04:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 250,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-08-05T04:00:00.000Z",
    location: "",
    startDate: "2023-08-05T04:00:00.000Z",
    endDate: "2023-08-09T04:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 250,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-08-12T04:00:00.000Z",
    location: "",
    startDate: "2023-08-12T04:00:00.000Z",
    endDate: "2023-08-16T04:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 250,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
  {
    _id: "2023-08-19T04:00:00.000Z",
    location: "",
    startDate: "2023-08-19T04:00:00.000Z",
    endDate: "2023-08-23T04:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 250,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
];

export const sessionCost = {
  "2023-06-17T04:00:00.000Z": 200.0,
  "2023-06-24T04:00:00.000Z": 250.0,
  "2023-07-01T04:00:00.000Z": 200.0,
  "2023-07-08T04:00:00.000Z": 250.0,
  "2023-07-15T04:00:00.000Z": 250.0,
  "2023-07-22T04:00:00.000Z": 250.0,
  "2023-07-29T04:00:00.000Z": 250.0,
  "2023-08-05T04:00:00.000Z": 250.0,
  "2023-08-12T04:00:00.000Z": 250.0,
  "2023-08-19T04:00:00.000Z": 250.0,
};
