import ReactDOM from "react-dom";
import { useState, useLayoutEffect } from 'react';

export default function Portal({ children, wrapperId = "react-portal-wrapper" }) {
    const [wrapperElement, setWrapperElement] = useState(null);

    let element = document.getElementById(wrapperId);

    const createWrapperAndAppendToBody = (wrapperId) => {
        const wrapperElement = document.createElement('div');
        wrapperElement.setAttribute("id", wrapperId);
        document.body.appendChild(wrapperElement);
        return wrapperElement;
     }

    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId);
        let systemCreated = false;
        // if element is not found with wrapperId or wrapperId is not provided,
        // create and append to body
        if (!element) {
            systemCreated = true;
            element = createWrapperAndAppendToBody(wrapperId);
        }

        setWrapperElement(element);

        return () => {
            // delete the programatically created element
            if (systemCreated && element.parentNode) {
                element.parentNode.removeChild(element);
            }
        }
    }, [wrapperId]);

    if (wrapperElement === null) return null;

    return ReactDOM.createPortal(children, element);
//   // Create div to contain everything
//   const el = React.useMemo(() => document.createElement("div"), []);
//   // On mount function
//   React.useEffect(() => {
//     // work out target in the DOM based on parent prop
//     const target = parent && parent.appendChild ? parent : document.body;
//     // Default classes
//     const classList = ["portal-container"];
//     // If className prop is present add each class the classList
//     if (className) className.split(" ").forEach((item) => classList.push(item));
//     classList.forEach((item) => el.classList.add(item));
//     // Append element to dom
//     target.appendChild(el);
//     // On unmount function
//     return () => {
//       // Remove element from dom
//       target.removeChild(el);
//     };
//   }, [el, parent, className]);
//   // return the createPortal function
}