import React from "react";
import "./carousel.scss";
import { CarouselSlide } from "./CarouselSlide";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export function Carousel() {
  return (
    <div className="carouselContainer">
      <div className="swiperContainer">
        <Swiper
          cssMode={true}
          navigation={false}
          pagination={{
            clickable: true,
          }}
          loop={false}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          initialSlide={0}
          loopedSlides={1}
          modules={[Navigation, Pagination, Autoplay]}
          className="carouselSwiper"
        >
          <SwiperSlide className="front-carousel-slide slide-1">
            <CarouselSlide
              text="2024-2025 School Year Sign-Up is Now Open!"
              buttonText="Sign Up Now"
              link="https://campsarraka.campbrainregistration.com"
              notext={false}
            />
          </SwiperSlide>
          <SwiperSlide className="front-carousel-slide slide-2">
            <CarouselSlide
              text="2024-2025 School Year Sign-Up is Now Open!"
              buttonText="Sign Up Now"
              link="/programs/registration"
              notext={true}
            />
          </SwiperSlide>
          <SwiperSlide className="front-carousel-slide slide-3">
            <CarouselSlide
              text="2024-2025 School Year Sign-Up is Now Open!"
              buttonText="Sign Up Now"
              link="/programs/registration"
              notext={true}
            />
          </SwiperSlide>
          <SwiperSlide className="front-carousel-slide slide-4">
            <CarouselSlide
              text="2024-2025 School Year Sign-Up is Now Open!"
              buttonText="Sign Up Now"
              link="/programs/registration"
              notext={true}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
