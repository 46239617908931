import { useState } from "react";
import Dropdown from "./Dropdown";
import "./navbar.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const MenuItems = ({ items, mobile, open, setOpen, mobileDropdowns, setMobileDropdowns, index}) => {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);

  let itemClick = (item) => {
    setOpen(false);
    navigate(item.page);
  }

  let dropdownClick = (index) => {
    let items = [...mobileDropdowns];
    items[index] = !items[index];
    setMobileDropdowns(items);
  }

  return (
    <>
      <li className="menu-items">
        {items.submenu ? (
          <>
            <div
              className={`menu-items-menu-item ${dropdown ? "active" : ""}`}
              aria-expanded={(dropdown && !mobile) || (mobile && mobileDropdowns[index]) ? "true" : "false"}
              onMouseOver={() => setDropdown(true)}
              onMouseLeave={() => setDropdown(false)}
              onClick={() => dropdownClick(index)}>
              <p 
              className={`menu-items-item-name ${dropdown ? "active" : ""}
                menu-items-dropdown-name ${dropdown ? "active" : ""}`}>
                {items.title}
              </p>
            </div>
            {mobile ?
              <FontAwesomeIcon icon={faChevronDown} className={`chevronIcon ${mobileDropdowns[index] ? "chevronIcon--open" : ""}`} /> : null
            }
            <Dropdown 
              submenus={items.submenu} 
              dropdown={dropdown}
              setDropdown={setDropdown}
              mobileDropdowns={mobileDropdowns} 
              open={open}
              setOpen={setOpen}
              setMobileDropdowns={setMobileDropdowns}
              key={index}
              index={index}
              mobile={mobile}/>
          </>
        ) : (
          <div className="menu-items-menu-item" onClick={() => itemClick(items)}>
            <a className="menu-items-item-name" href={items.page}>{items.title}</a>
          </div>
        )}
      </li>
    </>
   );
  };

export default MenuItems;