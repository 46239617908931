import React from "react";
import "./CITProgramRegister.scss";
import { RegistrationForm } from "../../design-system/components/RegistrationForm/RegistrationForm";
import { sessions } from "./SummerCampSessions";

export function CITProgramRegister() {
  return (
    <div className="SchoolYearCampRegister">
      <img
        className="summer-camp-register-container-background"
        alt="background"
      />

      <div className="SummerCampRegister-section">
        <RegistrationForm sessions={sessions} cit={true} />
      </div>
    </div>
  );
}
