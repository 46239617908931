import React from "react";
import "./gallery.scss";
import img9398 from "../../assets/images/gallery_4.jpeg";
import img1847 from "../../assets/images/gallery_7.jpeg";
import img2554 from "../../assets/images/gallery_10.JPG";
import img2630 from "../../assets/images/gallery_13.JPG";
import img1779 from "../../assets/images/gallery_2.jpeg";
import img1778 from "../../assets/images/gallery_5.JPG";
import img1758 from "../../assets/images/gallery_8.JPG";
import img1735 from "../../assets/images/gallery_1.JPG";
import img1698 from "../../assets/images/gallery_14.JPG";
import img1439 from "../../assets/images/gallery_3.JPG";
import img1431 from "../../assets/images/gallery_6.JPG";
import img1409 from "../../assets/images/gallery_9.JPG";
import img1407_2 from "../../assets/images/gallery_12.JPG";
import gallery15 from '../../assets/images/gallery_15.JPG';
import gallery16 from '../../assets/images/gallery_16.JPG';
import gallery17 from '../../assets/images/gallery_17.JPG';
import gallery18 from '../../assets/images/gallery_18.JPG';
import gallery19 from '../../assets/images/gallery_19.JPG';
import gallery20 from '../../assets/images/gallery_20.JPG';
import gallery21 from '../../assets/images/gallery_21.JPG';
import gallery22 from '../../assets/images/gallery_22.JPG';
import gallery23 from '../../assets/images/gallery_23.JPG';
import gallery24 from '../../assets/images/gallery_24.JPG';
import gallery25 from '../../assets/images/gallery_25.JPG';

export function Gallery() {
    return (
        <div className="Gallery">
            <div className="Gallery-header-container">
                <div className="Gallery-textWrapper">
                    <h3 className="Gallery-header">Making Memories</h3>
                </div>
            </div>
            <img className="Gallery-background" alt="background" />

            <div className="Gallery-wrapper">
                <div className="Gallery-column">
                    <img className="Gallery-image" alt="Two campers in a having fun in a tree" src={img1735} />
                    <img className="Gallery-image" alt="Campers doing a fun art project" src={img9398} />
                    <img className="Gallery-image" alt="Camp Sarraka group pic" src={img1847} />
                    <img className="Gallery-image" alt="Jake and campers having fun" src={img2554} />
                    <img className="Gallery-image" alt="3 campers" src={img2630} />
                    <img className="Gallery-image" alt="camper with hockey stick" src={gallery15} />
                    <img className="Gallery-image" alt="camp pic" src={gallery18} />
                    <img className="Gallery-image" alt="camp pic" src={gallery21} />
                    <img className="Gallery-image" alt="camper showing off his art" src={gallery25} />
                </div>
                <div className="Gallery-column">
                    <img className="Gallery-image" alt="counselor Brody and a camper" src={img1779} />
                    <img className="Gallery-image" alt="counselor Brody" src={img1778} />
                    <img className="Gallery-image" alt="campers doing arts and crafts" src={img1758} />
                    <img className="Gallery-image" alt="2 campers in a tree" src={img1735} />
                    <img className="Gallery-image" alt="campers doing crafts" src={img1698} />
                    <img className="Gallery-image" alt="campers climbing in a tree" src={gallery16} />
                    <img className="Gallery-image" alt="after school sticker board" src={gallery19} />
                    <img className="Gallery-image" alt="camp pic" src={gallery22} />
                </div>
                <div className="Gallery-column">
                    <img className="Gallery-image" alt="camp pic" src={img1439} />
                    <img className="Gallery-image" alt="camper playing a game" src={img1431} />
                    <img className="Gallery-image" alt="camper playing a game" src={img1409} />
                    <img className="Gallery-image" alt="camper playing a game" src={img1407_2} />
                    <img className="Gallery-image" alt="campers with bicycles" src={gallery17} />
                    <img className="Gallery-image" alt="campers doing crafts" src={gallery20} />
                    <img className="Gallery-image" alt="small group of campers" src={gallery23} />
                    <img className="Gallery-image" alt="camp pic" src={gallery24} />
                </div>
            </div>
        </div>
    );
}