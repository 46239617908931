import React from "react";
import "./sessionForm.scss";
import { useForm, useFieldArray } from "react-hook-form";
import { SessionsFormModule } from "../SessionsFormModule/SessionsFormModule";
import { CostModule } from "../../../../../components/FormManager/CostModule";
import { useState, useEffect } from "react";

export function SessionForm({
  finalData,
  setFinalData,
  cost,
  setCost,
  finalSubmit,
  step,
  setStep,
  isLoading,
  setIsLoading,
  sessions,
  finass,
  type,
  grade,
  teacher,
  summercamp,
  afterSchool,
}) {
  const camperArrayName = "campers";
  var [sessionErrors, setSessionErrors] = useState([false]);

  const {
    handleSubmit,
    watch,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      campers: [
        {
          firstName: "",
          lastName: "",
          gender: "",
          birthday: "",
          grade: "",
          shirtSize: "",
          specialConsiderations: "",
          teacher: "",
          sessions: [],
        },
      ],
    },
  });

  const {
    fields: camperFields,
    append: camperAppend,
    remove: camperRemove,
  } = useFieldArray({
    control,
    name: camperArrayName,
  });

  useEffect(() => {
    setValue("campers", finalData.campers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const finassChange = (e) => {
    let tempFinalData = finalData;
    if (e.target.checked) {
      tempFinalData.financialAssistance = true;
    } else {
      tempFinalData.financialAssistance = false;
    }
    setFinalData(tempFinalData);
  };

  const watchCamperField = watch(camperArrayName);

  const saveForm = () => {
    let tempFinalData = finalData;
    tempFinalData["campers"] = watchCamperField;
    setFinalData(tempFinalData);
  };

  const updateCost = (index) => {
    let newCost = cost;
    let camper = watchCamperField[index];
    for (const [, value] of Object.entries(camper.sessions)) {
      newCost -= value.price;
    }
    setCost(newCost);
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    console.log(data);

    let tempSessionErrors = sessionErrors;
    for (let i = 0; i < data["campers"].length; i++) {
      tempSessionErrors[i] = false;
    }
    setSessionErrors(tempSessionErrors);
    var sessionError = false;
    for (let i = 0; i < data["campers"].length; i++) {
      if (Object.keys(data["campers"][i]["sessions"]).length < 1) {
        let tempSessionErrors = sessionErrors;
        tempSessionErrors[i] = true;
        setSessionErrors(tempSessionErrors);
        sessionError = true;
      }
    }
    if (!sessionError) {
      let tempFinalData = finalData;
      tempFinalData["campers"] = data["campers"];
      setFinalData(tempFinalData);
      finalSubmit();
    } else {
      setIsLoading(false);
      document
        .getElementById("formTop")
        .scrollIntoView({ behavior: "smooth" }, true);
    }
  };

  return (
    <div className="SessionForm">
      <form
        className="form-manager-sessions-section-container"
        onSubmit={handleSubmit(onSubmit)}
      >
        {camperFields.map((field, index) => (
          <fieldset className="form-manager-module-field" key={field.id}>
            <SessionsFormModule
              key={field.id}
              control={control}
              register={register}
              index={index}
              sessions={sessions}
              setValue={setValue}
              watch={watchCamperField}
              setCost={setCost}
              errors={errors}
              sessionError={sessionErrors[index]}
              setSessionErrors={setSessionErrors}
              sessionErrors={sessionErrors}
              type={type}
              grade={grade}
              teacher={teacher}
              summercamp={summercamp}
              afterSchool={afterSchool}
            />
            {camperFields.length > 1 && (
              <button
                className="form-manager-remove-button"
                type="button"
                onClick={() => {
                  if (camperFields.length > 1) {
                    updateCost(index);
                    camperRemove(index);
                    let tempSessionErrors = sessionErrors;
                    tempSessionErrors.splice(index, 1);
                    setSessionErrors(tempSessionErrors);
                  }
                }}
              >
                Remove
              </button>
            )}
          </fieldset>
        ))}
        <div className="form-manager-add-camper-container">
          <button
            className="form-manager-append-button"
            onClick={() => {
              camperAppend({ sessions: {} });
              let tempSessionErrors = sessionErrors;
              tempSessionErrors.push(false);
              setSessionErrors(tempSessionErrors);
            }}
          >
            Add Camper
          </button>
        </div>
        {finass && (
          <div className="summer-camp-register-sessions-form-content-container-finass-container">
            <input
              type="checkbox"
              value="finass"
              name="finass"
              className="summer-camp-register-sessions-form-content-container-finass-container-box"
              onChange={(e) => finassChange(e)}
            />
            <p className="summer-camp-register-sessions-form-content-container-finass-container-text">
              Check if you are interested in applying for financial assistance
            </p>
          </div>
        )}
        <div className="form-manager-navigation-container">
          <button
            className="form-manager-navigation-button"
            onClick={(e) => {
              document
                .getElementById("formTop")
                .scrollIntoView({ behavior: "smooth" }, true);
              saveForm();
              setStep((step -= 1));
            }}
          >
            Previous
          </button>
          <button
            className={`form-manager-append-button ${
              isLoading ? "disabled" : ""
            }`}
            type="submit"
            disabled={isLoading}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
