import React from "react";
import "./afterSchoolProgramPage.scss";
import { Link } from "react-router-dom";

export function AfterSchoolProgramPage() {
  return (
    <div className="AfterSchoolRegister" id="formTop">
      <div className="AfterSchoolRegister-header-container">
        <div className="AfterSchoolRegister-textWrapper">
          <h3 className="AfterSchoolRegister-header">
            Barcroft After&#8209;School Enrichment
          </h3>
          <div className="AfterSchoolRegister-subHeader">
            <a
              href="https://campsarraka.campbrainregistration.com"
              target="_blank"
              reloadDocument
              rel="noreferrer"
              className="info-section-button-link"
            >
              Sign Up Now
            </a>
          </div>
          {/* <div className="AfterSchoolRegister-closed">
            Registration is closed.
          </div> */}
        </div>
      </div>
      <img
        className="after-school-register-container-background"
        alt="background"
      />
      <div className="AfterSchoolRegister-content">
        <div className="AfterSchoolRegister-section">
          <div className="AfterSchoolRegister-sectionHeader">Details</div>

          <div className="AfterSchoolRegister-sectionContent">
            <div className="AfterSchoolRegister-price">
              <span className="AfterSchoolRegister-miniHeader">Time:</span>{" "}
              Fridays from <u>3:50 p.m. to 4:50 p.m.</u>
            </div>
            <div className="AfterSchoolRegister-time">
              <span className="AfterSchoolRegister-miniHeader">Price:</span>{" "}
              $120 (6 Enrichment Days)
            </div>
            <div className="AfterSchoolRegister-price">
              <span className="AfterSchoolRegister-miniHeader">Location:</span>{" "}
              Barcroft Elementary School
            </div>
            <div className="AfterSchoolRegister-extra">
              <span className="AfterSchoolRegister-miniHeader">
                Financial Aid:
              </span>{" "}
              We want all kids to have an opportunity for a fun and rewarding
              after-school experience.{" "}
              <b>
                If you are requesting financial aid, the cost for the entire
                enrichment program is $20.
              </b>
            </div>
            <div className="AfterSchoolRegister-extra">
              <span className="AfterSchoolRegister-miniHeader">
                Lottery System:
              </span>{" "}
              Fall session registration will be carried out via{" "}
              <u>lottery system.</u> Enrollment is open until{" "}
              <b>September 18</b>, and all families will be notified by{" "}
              <b>September 20</b> about their enrollment status.
            </div>
          </div>
        </div>

        <div className="AfterSchoolRegister-section">
          <div className="AfterSchoolRegister-sectionHeader">Schedule</div>
          <div className="AfterSchoolRegister-scheduleContent">
            <table className="AfterSchoolRegister-scheduleTable">
              <tr className="AfterSchoolRegister-scheduleTable-titlerow">
                <th colspan="2">Barcroft After-School Enrichment</th>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Enrichment Day #1</td>
                <td>
                  <b>October 4</b> (Friday)
                </td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Enrichment Day #2</td>
                <td>
                  <b>October 18</b> (Friday)
                </td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Enrichment Day #3</td>
                <td>
                  <b>October 25</b> (Friday)
                </td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Enrichment Day #4</td>
                <td>
                  <b>November 8</b> (Friday)
                </td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Enrichment Day #5</td>
                <td>
                  <b>November 15</b> (Friday)
                </td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Enrichment Day #6</td>
                <td>
                  <b>November 22</b> (Friday)
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
