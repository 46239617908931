import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";

import Logo from "../../assets/Logo_Black.svg";
import facebook from "../../assets/facebookLogo.png";
import instagram from "../../assets/instagramLogo.png";
import youtube from "../../assets/youtubeLogo.svg";

export function Footer() {
  return (
    <div className="footerContainer">
      <div className="container">
        <div className="footerInfoContainer">
          <div className="twoColumnFooter">
            <div className="footerSection">
              <h3 className="footerContactHeader">Contact Us</h3>
              <div className="footerSocialSection footerSocialSection-desktop">
                <a
                  href="https://www.facebook.com/people/Camp-Sarraka/100089114388539/?mibextid=LQQJ4d"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="socialLogo" src={facebook} alt="logo" />
                </a>
                <a
                  href="https://www.instagram.com/campsarraka/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="socialLogo" src={instagram} alt="logo" />
                </a>
                <a
                  href="https://www.youtube.com/@campsarraka"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="socialLogo" src={youtube} alt="logo" />
                </a>
              </div>
              <div className="footerSection">
                <div className="footerInfoSection">
                  <div>
                    <div>
                      <span>Phone: </span>
                      <a href="tel:703-409-9604">703-409-9604</a>
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>Email: </span>
                      <a href="mailto: ethan@sarraka.com">ethan@sarraka.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerSectionWithLogo">
              <Link to="/" reloadDocument>
                <img className="footerLogo" src={Logo} alt="logo" />
              </Link>
              <div className="footerLinksSection">
                <div className="siteLinksSection">
                  <Link to="../programs/registration" reloadDocument>
                    Sign Up Now
                  </Link>
                  <a
                    href="https://firstroadsouth.org"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Give Back
                  </a>
                </div>
              </div>
            </div>
            <div className="footerSocialSection footerSocialSection-mobile">
              <a
                href="https://www.facebook.com/people/Camp-Sarraka/100089114388539/?mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer"
              >
                <img className="socialLogo" src={facebook} alt="logo" />
              </a>
              <a
                href="https://www.instagram.com/campsarraka/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="socialLogo" src={instagram} alt="logo" />
              </a>
              <a
                href="https://www.youtube.com/@campsarraka"
                target="_blank"
                rel="noreferrer"
              >
                <img className="socialLogo" src={youtube} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
