import React from "react";
import "./registrationOptions.scss";
import { Link } from "react-router-dom";

export function RegistrationOptions() {
  return (
    <div className="RegistrationOptions">
      <div className="RegistrationOptions-header-container">
        <div className="RegistrationOptions-textWrapper">
          <h3 className="RegistrationOptions-header">Registration</h3>
        </div>
      </div>
      <img className="RegistrationOptions-background" alt="background" />
      <div className="RegistrationOptions-section">
        <div className="RegistrationOptions-sectionBody">
          <div className="RegistrationOptions-social">
            <a
              className="RegistrationOptions-social-anchor RegistrationOptions-social-anchor-summer-camp"
              href="https://campsarraka.campbrainregistration.com"
              target="_blank"
              rel="noreferrer"
            >
              <p className="RegistrationOptions-social-anchor-text">
                School Year Camps
              </p>
            </a>
            <div className="RegistrationOptions-social-column">
              <a
                className="RegistrationOptions-social-anchor"
                href="https://campsarraka.campbrainregistration.com"
                target="_blank"
                rel="noreferrer"
              >
                <p className="RegistrationOptions-social-anchor-text">
                  Barcroft After-School Enrichment
                </p>
              </a>
              <Link
                className="RegistrationOptions-social-anchor"
                to="../summercamp/register"
              >
                Summer Camp 2024
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
