import React from "react";
import "./parentForm.scss";
import { useForm } from "react-hook-form";
import { ParentInfoFormModule } from "../InfoFormModule/ParentInfoFormModule";
import { useEffect } from "react";

export function ParentForm({finalData, setFinalData, step, setStep}){

  const parentArrayName = "parents";

  const { handleSubmit, watch, control, register, setValue, formState: { errors } } = useForm({
    defaultValues: {
      parents: [{
        primary: true,
        firstName: "",
        lastName: "",
        email: "",
        cellPhone: "",
        maritalStatus: "",
        relationship: ""
      }]
    }
  });

  const watchParentField = watch(parentArrayName);

  useEffect(() => {
    setValue("parents.0.firstName", finalData.parents[0].firstName);
    setValue("parents.0.lastName", finalData.parents[0].lastName);
    setValue("parents.0.email", finalData.parents[0].email);
    setValue("parents.0.cellPhone", finalData.parents[0].cellPhone);
  }, []);

  const onSubmit = (data) => {
    // Add this info the finalData
    let tempFinalData = finalData;
    tempFinalData["parents"] = data["parents"];
    setFinalData(tempFinalData);
    setStep(step+=1);
  }

  return (
    <form className={"ParentForm"} onSubmit={handleSubmit(onSubmit)}>
      <fieldset
        className="form-manager-module-field">
        <ParentInfoFormModule
          control={control}
          register={register}
          setValue={setValue}
          watch={watchParentField}
          errors={errors}
        />
      </fieldset>
      <div className="form-manager-navigation-container">
        <button className="form-manager-navigation-button disabled"
          onClick={(e) => {
            document.getElementById("formTop").scrollIntoView({behavior: 'smooth'}, true);
          }}
          disabled = {true} >
            Previous
        </button>
        <button className="form-manager-navigation-button" 
          onClick={(e) => {
            document.getElementById("formTop").scrollIntoView({behavior: 'smooth'}, true);
          }}
          type="submit">
            Next
        </button>
      </div>
    </form>
  )
}