import React from "react";
import "./testimonials.scss";

export function Testimonials() {
    return (
        <div className="Testimonials">
            <div className="Testimonials-header-container">
                <div className="Testimonials-textWrapper">
                    <h3 className="Testimonials-header">Voices from our Community</h3>
                    <div className="Testimonials-mission">
                    We are humbled and inspired by the positive feedback from parents and families.
                    </div>
                </div>
            </div>
            <img className="Testimonials-background" alt="background"/>

            <div className="Testimonials-section">
                <div className="Testimonials-sectionBody">
                    <div className="Testimonials-storyPart">
                        <div className="Testimonials-storyPart--text">
                            <p>
                            “My 12 year-old son attended Camp Sarraka this summer for the first time 
                            and had an absolutely amazing time!  He’s been to many camps over the years 
                            and never really had fun or truly enjoyed himself. From the very first day 
                            of Camp Sarraka, my son was over the moon!  Fabulous activities, amazing 
                            counselors and leaders, and just a terrific vibe. The kids range in ages but 
                            the counselors make all of the activities work for all ages. My son is 
                            already looking forward to two weeks of camp next summer!  He was excited to 
                            go to camp every day and came home every evening exhausted and happy. Best 
                            camp ever!”
                            </p>
                        </div>
                        <div className="Testimonials-storyPart--author">
                            <p className="Testimonials-storyPart--author--text">
                            Kasia, parent of 1st time camper, Summer ‘22
                            </p>
                        </div>
                    </div>
                </div>

                <div className="Testimonials-sectionBody Testimonials-sectionBody--alt">
                    <div className="Testimonials-storyPart">
                        <div className="Testimonials-storyPart--text">
                            <p>
                            “I must get asked at least 10-15 times every morning about how long it is until 
                            camp:) They LOVE their Camp Sarraka.”
                            </p>
                        </div>
                        <div className="Testimonials-storyPart--author">
                            <p className="Testimonials-storyPart--author--text">
                            Shane, parent of 3 campers who have attended for several years
                            </p>
                        </div>
                    </div>
                </div>

                <div className="Testimonials-sectionBody">
                    <div className="Testimonials-storyPart">
                        <div className="Testimonials-storyPart--text">
                            <p>
                            “Our sons ages 10 and 13 have absolutely loved camp with Jake and 
                            Brody and their amazing friends for years! They teach them valuable 
                            lessons about being kind, good sportsmanship, and trying new things. 
                            Of particular importance to me is how inclusive they are, making my 
                            son on the autism spectrum feel like he can participate in all the 
                            same activities and encouraging him when he gets frustrated. In our 
                            experience, no other camp even comes close to how much fun they have 
                            and how much they learn at Camp SCAM/Sarraka.”

                            </p>
                        </div>
                        <div className="Testimonials-storyPart--author">
                            <p className="Testimonials-storyPart--author--text">
                            Erica & Thor, parents and Arlington Public High School Teacher
                            </p>
                        </div>
                    </div>
                </div>

                <div className="Testimonials-sectionBody Testimonials-sectionBody--alt">
                    <div className="Testimonials-storyPart">
                        <div className="Testimonials-storyPart--text">
                            <p>
                            “Thank you Jake and Brody for Camp Sarraka! How you’re able to bring 
                            a group of kids with varying ages and abilities together for what they 
                            ALL consider to be “THE BEST DAY EVER!!!!” is basically a miracle. I 
                            can’t thank you enough for the positive environment you’ve created. 
                            You have so much creativity, enthusiasm, and energy, it’s no surprise 
                            that the kids love Camp Sarraka.”
                            </p>
                        </div>
                        <div className="Testimonials-storyPart--author">
                            <p className="Testimonials-storyPart--author--text">
                            Alison, parent whose son has attended Camp SCAM/Sarraka for four years
                            </p>
                        </div>
                    </div>
                </div>

                <div className="Testimonials-sectionBody">
                    <div className="Testimonials-storyPart">
                        <div className="Testimonials-storyPart--text">
                            <p>
                            “Camp Sarraka provides an inclusive and energetic play environment for children. 
                            The focus on teamwork and friendly athletic competition makes for a safe and 
                            positive summer camp experience. This camp is at the top of my kids’ summer 
                            wish list every year.”
                            </p>
                        </div>
                        <div className="Testimonials-storyPart--author">
                            <p className="Testimonials-storyPart--author--text">
                            Julie, parent of 3 long time campers (and elementary school teacher)
                            </p>
                        </div>
                    </div>
                </div>

                <div className="Testimonials-sectionBody Testimonials-sectionBody--alt">
                    <div className="Testimonials-storyPart">
                        <div className="Testimonials-storyPart--text">
                            <p>
                            “You guys are amazing and there is nothing else out there as inclusive 
                            with both neurotypical and neurodivergent populations.”
                            </p>
                        </div>
                        <div className="Testimonials-storyPart--author">
                            <p className="Testimonials-storyPart--author--text">
                            Liz, parent whose daughter attended Camp SCAM/Sarraka the past 2 years
                            </p>
                        </div>
                    </div>
                </div>

                <div className="Testimonials-sectionBody">
                    <div className="Testimonials-storyPart">
                        <div className="Testimonials-storyPart--text">
                            <p>
                            “So happy we got turned onto this best kept summer secret! Thanks, guys!”
                            </p>
                        </div>
                        <div className="Testimonials-storyPart--author">
                            <p className="Testimonials-storyPart--author--text">
                            Mona, parent of 1st time camper, Summer ‘22
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}