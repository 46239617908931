import React from "react";
import "./sarrakaships.scss";
import sarrakaships_1 from '../../assets/images/sarrakaships_1.JPG';
import sarrakaships_2 from '../../assets/images/sarrakaships_2.JPG';
import sarrakaships_logo from '../../assets/images/sarrakaships_logo.png';
import { VideoEmbed } from "../../design-system/components/VideoEmbed/VideoEmbed";

export function Sarrakaships() {
    return (
        <div className="Sarrakaships">
            <div className="Sarrakaships-header-container">
                <div className="Sarrakaships-textWrapper">
                    <h3 className="Sarrakaships-header">Camp Sarraka Scholarships</h3>
                </div>
            </div>
            <img className="Sarrakaships-background" alt="background"/>

            <div className="Sarrakaships-content">
                <div className="Sarrakaships-subText">
                    Every kid deserves the chance to grow, thrive, and have fun. That’s why we are so excited to work with 1st Road South, a 501(c)3 nonprofit organization, to provide scholarships - or “Sarrakaships” as we call them - for kids who cannot afford to attend summer camp or after-school programs.
                    <br /><br />
                    Help us make sure every kid has a chance to reach their full potential.
                    <br /><br />
                    All donations are 100% tax-deductible.
                </div>
                <VideoEmbed embedId='i3iEIdmLpsk' />
                <div className="Sarrakaships-buttonWrapper">
                  <a href="https://firstroadsouth.org" className="Sarrakaships-button" target="_blank" rel="noreferrer">Learn More</a>
                </div>
                <div className="Sarrakaships-images">
                    <div className="Sarrakaships-imageWrapper">
                        <img src={sarrakaships_1} className="Sarrakaships-image" alt="camper sitting in a tree" />     
                    </div>
                    <div className="Sarrakaships-imageWrapper">
                        <img src={sarrakaships_2} className="Sarrakaships-image" alt="campers in the rain" />     
                    </div>
                </div>
                <div className="Sarrakaships-logo-container">
                    <div className="Sarrakaships-imageWrapper">
                        <img src={sarrakaships_logo} className="Sarrakaships-logo" alt="first road south logo" />     
                    </div>
                </div>
            </div>
        </div>
    );
}