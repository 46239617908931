export const testimonials = [
  {
    text: "I must get asked at least 10-15 times every morning about how long it is until camp:) They LOVE their Camp Sarraka.",
    author: "Shane, parent of 3 campers who have attended for several years"
  },
  {
    text: "So happy we got turned onto this best kept summer secret! Thanks, guys!",
    author: "Mona, parent of 1st time camper, Summer ‘22"
  },
  {
    text: "Camp Sarraka provides an inclusive and energetic play environment for children. The focus on teamwork and friendly athletic competition makes for a safe and positive summer camp experience. This camp is at the top of my kids’ summer wish list every year.",
    author: "Julie, parent of 3 long time campers (and elementary school teacher)"
  }
]