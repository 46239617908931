import React from "react";
import { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { SessionContainer } from "./SessionContainer";
import { Input } from "../../../../../components/Input/Input";
import "./sessionsFormModule.scss";

export function SessionsFormModule({
  register,
  index,
  value,
  control,
  sessions,
  setValue,
  watch,
  setCost,
  errors,
  sessionError,
  sessionErrors,
  setSessionErrors,
  type,
  grade,
  teacher,
  summercamp,
  afterSchool,
}) {
  const [selection, setSelection] = useState({});
  const [sessionActive, setSessionActive] = useState({});
  let [mobile, setMobile] = useState(false);
  let [allergies, setAllergies] = useState(false);

  // Fetch sessions based on camp type

  const sessionClick = async (sess) => {
    setSessionActive((prevSessionActive) => ({
      ...prevSessionActive,
      [sess._id]: !sessionActive[sess._id],
    }));
    if (selection.hasOwnProperty(sess._id)) {
      removeItem(sess);
    } else {
      let tempSessionErrors = sessionErrors;
      tempSessionErrors[index] = false;
      setSessionErrors(tempSessionErrors);
      addItem(sess);
    }
  };

  const addItem = (item) => {
    setSelection((prevSelection) => ({
      ...prevSelection,
      [item._id]: item,
    }));

    setCost((prevCost) => {
      return prevCost + item.price;
    });
  };

  const removeItem = (item) => {
    setSelection((prevSelection) => {
      const copy = { ...prevSelection };
      delete copy[item._id];
      return copy;
    });

    setCost((prevCost) => {
      return prevCost - item.price;
    });
  };

  useEffect(() => {
    let dict = watch[index].sessions;
    for (const [key, value] of Object.entries(dict)) {
      setSessionActive((prevSessionActive) => ({
        ...prevSessionActive,
        [key]: true,
      }));
      addItem(value);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 1200) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    });
    if (window.innerWidth <= 1200) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue(`campers.${index}.sessions`, selection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  const firstColumn = (sess, ind) => {
    if (mobile || !(ind >= sessions.length / 2)) {
      return (
        <SessionContainer
          key={ind}
          sessionClick={sessionClick}
          active={sessionActive[sess._id]}
          sess={sess}
        />
      );
    }
  };

  const secondColumn = (sess, ind) => {
    if (!mobile && ind >= sessions.length / 2) {
      return (
        <SessionContainer
          key={ind}
          sessionClick={sessionClick}
          active={sessionActive[sess._id]}
          sess={sess}
        />
      );
    }
  };

  return (
    <div className="summer-camp-register-sessions-form-content-container">
      {/* <h3 className="summer-camp-register-sessions-form-content-title">Camper {index+1}</h3> */}
      <div className="sessions-form-module-name-container">
        <div className="sessions-first-row-fields">
          <Input
            // Send id to overwrite parts of styling
            className="sessions-form-module-text-input"
            title={type + " First Name"}
            placeholder="Enter First Name"
            formID={`campers.${index}.firstName`}
            register={register}
            validation={{ required: "First Name is Required" }}
            error={
              errors.campers
                ? errors.campers[index]
                  ? errors.campers[index].firstName
                    ? errors.campers[index].firstName
                    : undefined
                  : undefined
                : undefined
            }
          />
          <Input
            className="sessions-form-module-text-input"
            title={type + " Last Name"}
            placeholder="Enter Last Name"
            formID={`campers.${index}.lastName`}
            register={register}
            validation={{ required: "Last Name is Required" }}
            error={
              errors.campers
                ? errors.campers[index]
                  ? errors.campers[index].lastName
                    ? errors.campers[index].lastName
                    : undefined
                  : undefined
                : undefined
            }
          />
          <Input
            className="sessions-form-module-text-input"
            title={type + " Date of Birth"}
            placeholder="01/01/2022"
            formID={`campers.${index}.birthday`}
            register={register}
            validation={{
              required: "Birthday is Required",
              pattern:
                /^(?:0[1-9]|1[012])[-/.](?:0[1-9]|[12][0-9]|3[01])[-/.](?:19\d{2}|20[01][0-9]|2020)\b/i,
            }}
            error={
              errors.campers
                ? errors.campers[index]
                  ? errors.campers[index].birthday
                    ? errors.campers[index].birthday
                    : undefined
                  : undefined
                : undefined
            }
            patternError={"Must be valid birthday"}
          />
        </div>
        {grade || teacher ? (
          <div className="sessions-second-row-fields">
            {grade ? (
              <Input
                className="sessions-form-module-text-input"
                title={type + " Grade"}
                placeholder="Enter Grade Year"
                formID={`campers.${index}.grade`}
                register={register}
                validation={{ required: "Grade is Required" }}
                error={
                  errors.campers
                    ? errors.campers[index]
                      ? errors.campers[index].grade
                        ? errors.campers[index].grade
                        : undefined
                      : undefined
                    : undefined
                }
              />
            ) : null}
            {teacher ? (
              <Input
                className="sessions-form-module-text-input"
                title={type + " Teacher Name"}
                placeholder="Enter Teacher's Name"
                formID={`campers.${index}.teacher`}
                register={register}
                validation={{ required: "Teacher is Required" }}
                error={
                  errors.campers
                    ? errors.campers[index]
                      ? errors.campers[index].teacher
                        ? errors.campers[index].teacher
                        : undefined
                      : undefined
                    : undefined
                }
              />
            ) : null}
          </div>
        ) : null}
      </div>
      {sessionError && (
        <p className="session-error-text">
          *Must select at least 1 session per camper
        </p>
      )}
      <Controller
        render={() => (
          <div className="summer-camp-register-sessions-form-sessions-container">
            <div className="summer-camp-register-sessions-form-sessions-column-1">
              {sessions.map((sess, index) => firstColumn(sess, index))}
            </div>
            <div className="summer-camp-register-sessions-form-sessions-column-2">
              {sessions.map((sess, index) => secondColumn(sess, index))}
            </div>
          </div>
        )}
        name={`campers.${index}.sessions`}
        control={control}
        multiple
      />
      {
        <div className="summer-camp-register-sessions-form-content-container-finass-container">
          {afterSchool ? (
            <></>
          ) : (
            <>
              <input
                type="checkbox"
                value={true}
                name="beforecare"
                {...register(`campers.${index}.beforecare`, {})}
                className="summer-camp-register-sessions-form-content-container-finass-container-box"
              />
              <p className="summer-camp-register-sessions-form-content-container-finass-container-text">
                Check here to add before-care (8:00 am - 9:00 am) for $50 per
                week
              </p>
              <input
                type="checkbox"
                value={true}
                name="aftercare"
                {...register(`campers.${index}.aftercare`, {})}
                className="summer-camp-register-sessions-form-content-container-finass-container-box"
              />
              <p className="summer-camp-register-sessions-form-content-container-finass-container-text">
                Check here to add after-care (4:00 pm - 5:30 pm) for $60 per
                week
              </p>
            </>
          )}
          <input
            type="checkbox"
            value={true}
            name="allergies"
            {...register(`campers.${index}.allergies`, {})}
            onChange={() => setAllergies(!allergies)}
            className="summer-camp-register-sessions-form-content-container-finass-container-box"
          />

          <p className="summer-camp-register-sessions-form-content-container-finass-container-text">
            Check here for any allergies or medications
          </p>
          {allergies && (
            <input
              placeholder="Please specify..."
              {...register(`campers.${index}.allergiesSpecified`)}
              className="summer-camp-register-allergies-textarea"
            />
          )}
        </div>
      }
    </div>
  );
}
