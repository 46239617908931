import React from "react";
import "./input.scss";
import { useState } from "react";

export function Input({ 
  placeholder="",
  title="", 
  register, 
  formID,
  className="base-outer-container",
  error,
  validation,
  patternError}) {

  const [focused, setFocused] = useState(false);

  return (
    <div className={className}>
      <div className={`base-input-container ${focused ? "focused" : ""}`}>
        <h6 className="input-title">{title}</h6>
        <input 
          className="base-input"
          placeholder={placeholder}
          {... register ? register(formID, validation) : {}}
          onFocus={(e) => {
            e.target.placeholder = ''
            setFocused(true)
          }}
          onBlur={(e) => {
            e.target.placeholder = placeholder
            setFocused(false)
          }}
          autoComplete={"off"}
        />
        <p className="error-text">{error ? "*" : ""}{error ? error.type === "pattern" ? patternError : error.message : ""}</p>
      </div>
    </div>
  )
}