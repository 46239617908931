import React from "react";
import "./videoEmbed.scss";

export function VideoEmbed({ embedId }) {
  return (
    <div className="VideoEmbed">
        <div className="VideoEmbed-wrapper">
            <iframe
                width="800"
                height="450"
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube video introducing Camp Sarraka"
                loading="lazy"
            />
        </div>
    </div>
  )
}