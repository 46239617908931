import React from "react";
import "./citprogram.scss";
import { Link } from "react-router-dom";

export function CITProgram() {
  return (
    <div className="CITRegister">
      <div className="CITRegister-header-container">
        <div className="CITRegister-textWrapper">
          <h3 className="CITRegister-header">
            Counselor In Training (CIT) Program
          </h3>
          <div className="AfterSchoolRegister-subHeader">
            <Link
              to="../summercamp/citprogram/register"
              reloadDocument
              className="info-section-button-link"
            >
              Sign Up Now
            </Link>
          </div>
        </div>
      </div>
      <img className="cit-register-container-background" alt="background" />
      <div className="CITRegister-content">
        <div className="CITRegister-section">
          <div className="CITRegister-sectionHeader">CIT Details</div>

          <div className="CITRegister-sectionContent">
            <div className="CITRegister-details">
              <p>
                We encourage anyone age 13-15 who is looking for a rewarding
                summer experience to sign-up for our CIT program!
              </p>

              <p>
                Counselors In Training (CITs) are an important part of the Camp
                Sarraka family. They enjoy all the benefits of the Camp Sarraka
                experience while taking on additional responsibility to learn
                important leadership and teamwork skills. CITs assist with camp
                from 9 a.m. - 4 p.m. every day, and their roles include engaging
                with younger campers as positive role models, facilitating camp
                programming, leading activities, and even captaining teams
                throughout the week. CITs are also assigned a mentor counselor
                to help with their development and will participate in
                team-building and bonding activities with the other CITs and
                counselors – including organizational meetings, group games, and
                team projects. The goal of the program is to help CITs
                eventually become successful Camp Sarraka counselors!
              </p>

              <p>
                If you have any questions about the CIT program, please contact
                Jake (Jake@sarraka.com) or Ethan (Ethan@sarraka.com).
              </p>
            </div>

            <div className="CITRegister-ages">
              <span className="CITRegister-miniHeader">Ages:</span> 13-15
            </div>

            <div className="CITRegister-location">
              <span className="CITRegister-miniHeader">Location:</span>{" "}
              Unitarian Universalist Church of Arlington, VA (4444 Arlington
              Blvd, Arlington VA 22204).
            </div>

            <div className="CITRegister-time">
              <span className="CITRegister-miniHeader">Time:</span> 9am - 4pm
            </div>

            <div className="CITRegister-price">
              <span className="CITRegister-miniHeader">Price:</span> $250 per
              week
            </div>
          </div>
        </div>

        <div className="AfterSchoolRegister-section">
          <div className="AfterSchoolRegister-sectionHeader">Schedule</div>
          <div className="AfterSchoolRegister-scheduleContent">
            <table className="AfterSchoolRegister-scheduleTable">
              <tr className="AfterSchoolRegister-scheduleTable-titlerow">
                <th colspan="2">Schedule</th>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 1</td>
                <td>June 17 - June 21 (no camp June 19)</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 2</td>
                <td>June 24 - June 28</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 3</td>
                <td>July 1 - July 5 (no camp July 4)</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 4</td>
                <td>July 8 - July 12</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 5</td>
                <td>July 15 - July 19</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 6</td>
                <td>July 22 - July 26</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 7</td>
                <td>July 29 - August 2</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 8</td>
                <td>August 5 - August 9</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 9</td>
                <td>August 12 - August 16</td>
              </tr>
              <tr className="AfterSchoolRegister-scheduleTable-row">
                <td>Week 10</td>
                <td>August 19 - August 23</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
