export const sessions = [
  {
    _id: "2024-04-12T06:00:00.000Z",
    location: "",
    startDate: "2024-04-12T06:00:00.000Z",
    endDate: "2024-05-31T06:00:00.000Z",
    type: "",
    startTime: "9:00",
    endTime: "4:00",
    price: 105,
    capacity: 0,
    extendedMorning: false,
    extendedAfternoon: false,
    etcDetail: "",
  },
];

export const sessionCost = {
  "2024-04-12T06:00:00.000Z": 105.0,
};
