import "./App.scss";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Profile } from "./pages/Profile/Profile";
import { HomePage } from "./pages/Home/HomePage";
import { LoginPage } from "./pages/Login/LoginPage";
import { AdminPage } from "./pages/Admin/AdminPage";
import { Navbar } from "./pages/Navbar/Navbar";
import { Footer } from "./pages/Footer/Footer";
import { SummerCampFAQs } from "./pages/SummerCampAbout/SummerCampAbout";
import { SummerCampRegisterPage } from "./pages/SummerCampRegistration/SummerCampRegisterPage";
import { Gallery } from "./pages/Gallery/Gallery";
import { About } from "./pages/About/About";
import { WellnessAndSafety } from "./pages/WellnessAndSafety/WellnessAndSafety";
import { Testimonials } from "./pages/Testimonials/Testimonials";
import { Contact } from "./pages/Contact/Contact";
import { MeetCounselors } from "./pages/MeetCounselors/MeetCounselors";
import { Sarrakaships } from "./pages/Sarrakaships/Sarrakaships";
import { CITProgram } from "./pages/CITProgram/CITProgram";
import { Forms } from "./pages/Forms/Forms";
import { AfterSchoolProgramPage } from "./pages/AfterSchoolProgram/AfterSchoolProgramPage";
import { AfterSchoolProgramRegistration } from "./pages/AfterSchoolRegistration/AfterSchoolProgramRegistration";
import { SchoolYearCampsPage } from "./pages/SchoolYearCamps/SchoolYearCampsPage";
import { SchoolYearCampsRegistration } from "./pages/SchoolYearCampsRegistration/SchoolYearCampsRegistration";
import { RegistrationOptions } from "./pages/RegistrationOptions/RegistrationOptions";
import { SummerCampRegistrationForm } from "./pages/SummerCampRegistrationForm/SummerCampRegistrationForm";
import { CITProgramRegister } from "./pages/CITProgramRegister/CITProgramRegister";

function App() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  return (
    <BrowserRouter>
      <Navbar open={open} setOpen={setOpen} />

      <div className={`content ${open ? "content--mobileOpen" : ""}`}>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          {/* <Route exact path="/login/success" />
          <Route exact path="/login/error" element={<p>Error!</p>}/>
          <Route exact path="/profile" element={<Profile/>}/> */}
          <Route exact path="/home" element={<HomePage />} />
          {/* <Route exact path="/login" element={<LoginPage/>}/>
          <Route exact path="/admin" element={<AdminPage />} /> */}
          <Route exact path="/summercamp/faq" element={<SummerCampFAQs />} />
          <Route
            exact
            path="/summercamp"
            element={<SummerCampRegisterPage />}
          />
          <Route
            exact
            path="/summercamp/register"
            element={<SummerCampRegistrationForm />}
          />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/about" element={<About />} />
          <Route
            exact
            path="/wellnessandsafety"
            element={<WellnessAndSafety />}
          />
          <Route exact path="/testimonials" element={<Testimonials />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/team" element={<MeetCounselors />} />
          {/* <Route exact path="/forms" element={<Forms />} /> */}
          <Route exact path="/sarrakaships" element={<Sarrakaships />} />
          <Route exact path="/summercamp/citprogram" element={<CITProgram />} />
          <Route
            exact
            path="/summercamp/citprogram/register"
            element={<CITProgramRegister />}
          />
          <Route
            exact
            path="/programs/afterschool"
            element={<AfterSchoolProgramPage />}
          />
          <Route
            exact
            path="/programs/afterschool/register"
            element={<AfterSchoolProgramRegistration />}
          />
          <Route
            exact
            path="/programs/schoolyearcamps"
            element={<SchoolYearCampsPage />}
          />
          <Route
            exact
            path="/programs/schoolyearcamps/register"
            element={<SchoolYearCampsRegistration />}
          />
          <Route
            exact
            path="/programs/registration"
            element={<RegistrationOptions />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
