import React from "react";
import "./carouselSlide.scss";
import { Link } from "react-router-dom";

export function CarouselSlide(props) {
  return (
    <div className="textOnImageContainer">
      <h1 className={`textOnImage ${props.notext ? "transparent" : ""}`}>
        {props.text}
      </h1>
      <a
        href={props.link}
        target="_blank"
        rel="noreferrer"
        className="carousel-section-button"
      >
        <span className="carousel-section-button-link">{props.buttonText}</span>
      </a>
    </div>
  );
}
