import React from "react";
import "./homepageGallery.scss"
import kidsInTree from "../../../../assets/images/homepage_gallery_1.jpeg";
import img2554 from "../../../../assets/images/homepage_gallery_2.jpeg";
import img9398 from "../../../../assets/images/homepage_gallery_3.JPG";
import img1847 from "../../../../assets/images/homepage_gallery_4.jpeg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function HomepageGallery() {

  return (
    <div className="HomepageGallery">
      <div className="HomepageGallery-row">
        <LazyLoadImage className="HomepageGallery-image" alt="Two campers in a having fun in a tree" src={kidsInTree} />
        <LazyLoadImage className="HomepageGallery-image" alt="Campers dragon art work done in chalk" src={img2554} />
        <LazyLoadImage className="HomepageGallery-image" alt="Campers doing a fun art project" src={img9398} />
        <LazyLoadImage className="HomepageGallery-image" alt="Camp Sarraka group pic" src={img1847} />
      </div>
    </div>
  )
}