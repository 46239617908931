import React from "react";
import { useEffect } from "react";

import { InfoSection } from "./components/InfoSection/InfoSection";
import { Carousel } from "./components/MainCarousel/Carousel";
import { TestimonialsSection } from "./components/Testimonials/TestimonialsSection";
import { HomepageGallery } from "./components/HomepageGallery/HomepageGallery";
import { VideoEmbed } from "../../design-system/components/VideoEmbed/VideoEmbed";

import image1 from "../../assets/images/homepage_summer_1.jpeg";
import image2 from "../../assets/images/homepage_summer_2.jpeg";

import "./HomePage.scss";
import Logo from "../../assets/Logo_Black.svg";
import { Sarrakaships } from "./components/Sarrakaships/Sarrakaships";

export function HomePage() {
  const summerCampText = () => {
    return (
      <p>
        Camp Sarraka is all about getting kids outside, running around, being
        creative, and working together in an inclusive environment that makes
        every camper feel special. Every day is packed with sports, art
        projects, and fun activities that inspire teamwork, sportsmanship, and
        kindness.
      </p>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Carousel />
      <div className="homepage-video-subheader">What is Camp Sarraka?</div>
      <VideoEmbed embedId="6Eu2euH8xLY" />
      <div className="frontlogo">
        <img src={Logo} className="frontlogo-image" alt="logo" />
        <InfoSection image1={image1} image2={image2} text={summerCampText()} />
      </div>
      <div className="homepagebody">
        <HomepageGallery />
        <Sarrakaships />
        <TestimonialsSection />
      </div>
    </div>
  );
}
