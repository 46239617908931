export const menuItems = [
  {
    title: "Programs",
    page: "/programs",
    submenu: [
      {
        title: "After-School",
        page: "/programs/afterschool",
      },
      {
        title: "School Year Camps",
        page: "/programs/schoolyearcamps",
      },
    ],
  },
  {
    title: "Summer 2024",
    page: "/summercamp",
    submenu: [
      {
        title: "Sign Up",
        page: "/summercamp",
      },
      {
        title: "FAQs",
        page: "/summercamp/faq",
      },
      {
        title: "CIT Program",
        page: "/summercamp/citprogram",
      },
      // {
      //   title: "Forms",
      //   page: "/forms",
      // },
    ],
  },
  {
    title: "Learn More",
    page: "",
    submenu: [
      {
        title: "About Us",
        page: "/about",
      },
      {
        title: "Meet the Team",
        page: "/team",
      },
      {
        title: "Gallery",
        page: "/gallery",
      },
      {
        title: "Parent Testimonials",
        page: "/testimonials",
      },
      {
        title: "Sarrakaships",
        page: "/sarrakaships",
      },
      {
        title: "Contact Us",
        page: "/contact",
      },
    ],
  },
];
