import React from "react";
import "./infoFormModule.scss";
import { Input } from "../../../../../components/Input/Input";

export function ParentInfoFormModule({register, errors}) {

  return (
    <div className="summer-camp-register-info-form-content-container">
      <div className="summer-camp-register-info-form-section-container">
        {/* <h3 className="summer-camp-register-info-form-section-title">Parent Information</h3> */}
        <div className="summer-camp-register-info-form-parent-container">
          {/* <h4 className="summer-camp-register-info-form-parent-number">Primary Parent</h4> */}
          <div className="summer-camp-register-info-form-subsection-container">
            <Input
              className="summer-camp-register-info-form-text-input"
              placeholder="Enter First Name"
              title="Parent/Guardian First Name"
              autocomplete="off"
              formID={`parents.${0}.firstName`}
              register={register}
              errors={errors}
              validation={{required: "First Name is Required"}}
              error={errors.parents ? errors.parents[0] ? errors.parents[0].firstName ? errors.parents[0].firstName : undefined : undefined : undefined}
            />
            <Input
              className="summer-camp-register-info-form-text-input"
              title="Parent/Guardian Last Name"
              placeholder="Enter Last Name"
              autocomplete="off"
              formID={`parents.${0}.lastName`}
              register={register}
              errors={errors}
              validation={{required: "Last Name is Required"}}
              error={errors.parents ? errors.parents[0] ? errors.parents[0].lastName ? errors.parents[0].lastName : undefined : undefined : undefined}
            />
          </div>
          {/* <div className="summer-camp-register-info-form-subsection-container">
            <CustomSelect
              className="summer-camp-register-info-form-dropdown"
              options={titleOptions}
              placeholder={"Select Title"}
              onChange={onSelect}
              title={"Title"}
              index={0}
              type={"title"}
              selection={watch[0]["title"]}
            />
            <CustomSelect
              className="summer-camp-register-info-form-dropdown"
              options={suffixOptions}
              placeholder={"Select Suffix"}
              onChange={onSelect}
              title={"Suffix"}
              index={0}
              type={"suffix"}
              selection={watch[0]["suffix"]}
            />
          </div> */}
          <div className="summer-camp-register-info-form-subsection-container">
            <Input
              className="summer-camp-register-info-form-text-input"
              title="Parent/Guardian Email"
              placeholder="Enter Email"
              autocomplete="off"
              formID={`parents.${0}.email`}
              register={register}
              validation={{required: "Email is Required", pattern: /^(?:^|\s)[\w!#$%&'*+/=?^`{|}~-](\.?[\w!#$%&'*+/=?^`{|}~-]+)*@\w+[.-]?\w*\.[a-zA-Z]{2,3}\b/i}}
              error={errors.parents ? errors.parents[0] ? errors.parents[0].email ? errors.parents[0].email : undefined : undefined : undefined}
              patternError={"Must be valid email address"}
            />
            <Input
              className="summer-camp-register-info-form-text-input"
              title="Parent/Guardian Phone"
              placeholder="Enter Phone Number"
              autocomplete="off"
              formID={`parents.${0}.cellPhone`}
              register={register}
              validation={{required: "Cell Phone is Required", pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i}}
              error={errors.parents ? errors.parents[0] ? errors.parents[0].cellPhone ? errors.parents[0].cellPhone : undefined : undefined : undefined}
              patternError={"Must be valid US Number"}
            />
          </div>
          {/* <div className="summer-camp-register-info-form-subsection-container">
            <Input
              className="summer-camp-register-info-form-text-input"
              title="Relationship"
              placeholder="Enter Relationship to Camper"
              autocomplete="off"
              formID={`parents.${0}.relationship`}
              register={register}
            />
          </div> */}
        </div>
        {/* <div className="summer-camp-register-info-form-parent-container">
          <h4 className="summer-camp-register-info-form-parent-number">Secondary Parent</h4>
          <div className="summer-camp-register-info-form-subsection-container">
            <Input
              className="summer-camp-register-info-form-text-input"
              placeholder="Enter Parents First Name"
              title="First Name"
              autocomplete="off"
              formID={`parents.${1}.firstName`}
              register={register}
            />
            <Input
              className="summer-camp-register-info-form-text-input"
              title="Last Name"
              placeholder="Enter Parents Last Name"
              autocomplete="off"
              formID={`parents.${1}.lastName`}
              register={register}
            />
          </div>
          <div className="summer-camp-register-info-form-subsection-container">
            <CustomSelect
              className="summer-camp-register-info-form-dropdown"
              options={titleOptions}
              placeholder={"Select Title"}
              onChange={onSelect}
              title={"Title"}
              index={1}
              type={"title"}
              selection={watch[1]["title"]}
            />
            <CustomSelect
              className="summer-camp-register-info-form-dropdown"
              options={suffixOptions}
              placeholder={"Select Suffix"}
              onChange={onSelect}
              title={"Suffix"}
              index={1}
              type={"suffix"}
              selection={watch[1]["suffix"]}
            />
          </div>
          <div className="summer-camp-register-info-form-subsection-container">
            <Input
              className="summer-camp-register-info-form-text-input"
              title="Email"
              placeholder="Enter Parents Email"
              autocomplete="off"
              formID={`parents.${1}.email`}
              register={register}
            />
            <Input
              className="summer-camp-register-info-form-text-input"
              title="Phone Number"
              placeholder="Enter Parents Phone Number"
              autocomplete="off"
              formID={`parents.${1}.phoneNumber`}
              register={register}
            />
          </div>
          <div className="summer-camp-register-info-form-subsection-container">
            <Input
              className="summer-camp-register-info-form-text-input"
              title="Relationship"
              placeholder="Enter Relationship to Camper"
              autocomplete="off"
              formID={`parents.${1}.relationship`}
              register={register}
            />
          </div>
        </div> */}
      </div>
    </div>
  )
}