import React from "react";
import "./about.scss";
import BrodyWithCampers from '../../assets/images/about_2.jpeg';
import CampSarraka1 from '../../assets/images/about_1.JPG';
import CampSarrakaBoard from '../../assets/images/about_3.jpeg';

export function About() {
    return (
        <div className="About">
            <div className="About-header-container">
                <div className="About-textWrapper">
                    <h3 className="About-header">Our Mission</h3>
                    <div className="About-mission">
                    To provide an inclusive environment that
                    brings kids together and inspires
                    creativity, activity, kindness, well-being,
                    and fun.
                    </div>
                </div>
            </div>
            <img className="About-background" alt="background"/>

            <div className="About-section">
                <div className="About-subHeader About-subHeader--story">
                    Our Story
                </div>

                <div className="About-sectionBody">
                    <div className="About-storyPart">
                        <div className="About-textImageWrapper">
                            <div className="About-storyPart--text">
                                <p>
                                From the time my brother Brody and I were old enough to play, we loved running and exploring with other kids in the neighborhood. A few neighbors noticed we were always outside having fun and many younger kids were tagging along. They asked us to create a week of “summer camp” and we loved the idea. Originally known as Camp SCAM (Sports, Crafts, And Memories), we started our camp in 2013 when we were 13 and 11 years old. Best summer job ever. 
                                </p>
                            </div>
                            <img className="About-storyPart--image" alt="Brody Karton with campers" src={CampSarraka1} />
                        </div>
                    </div>
                    <div className="About-storyPart About-storyPart--middle">
                        <div className="About-textImageWrapper">
                            <img className="About-storyPart--image About-storyPart--groupPic" alt="Camp Sarraka group pic" src={BrodyWithCampers} />
                            <div className="About-storyPart--text">
                                <p>
                                    Growing exclusively through word of mouth, we have run our camp ever since. Many of our campers have become CITs and counselors as the Camp Sarraka family continues to grow. Every day is packed with sports, art projects, and creative activities like brain-teaser obstacle courses and lip-sync battles. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="About-storyPart">
                        <div className="About-textImageWrapper">
                            <div className="About-storyPart--text">
                                <p>
                                    In August 2022, we officially changed the name from Camp SCAM to Camp Sarraka (a magical world filled with creativity and adventure that we created as kids). We also got one of our best friends, Ethan, to join the team. Our philosophy remains the same: share the important lessons we learned about sportsmanship, competition, and well-being – all while creating an environment where kids can explore, take chances, and have fun.
                                </p>
                                <p className="About-storyPart--authors">
                                    - Jake and Brody
                                </p>
                            </div>
                            <img className="About-storyPart--image" alt="Camp Sarraka Board" src={CampSarrakaBoard} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}