import React from "react";
import "./wellnessandsafety.scss";

export function WellnessAndSafety() {
    return (
        <div className="Safety">
            <div className="Safety-header-container">
                <div className="Safety-textWrapper">
                    <h3 className="Safety-header">Wellness & Safety</h3>
                    <div className="Safety-mission">
                        Safety is extremely important, and we take it very seriously here at Camp Sarraka.
                    </div>
                </div>
            </div>
            <img className="Safety-background" alt="background"/>

            <div className="Safety-section">
                <div className="Safety-subHeader Safety-subHeader--story">
                    Medications
                </div>

                <div className="Safety-sectionBody">
                    <div className="Safety-storyPart">
                        <div className="Safety-textImageWrapper">
                            <div className="Safety-storyPart--text">
                                <p>
                                    Camp Sarraka has been a fun, active, and creative place for children in Arlington since
                                    2013. Originally known as Camp SCAM (Sports, Crafts, And Memories), it was created in the
                                    summer of 2013 by Jake and Brody Karton, when they were in 8th and 6th grades, respectively.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Safety-subHeader Safety-subHeader--story">
                    Health on Arrival
                </div>
                <div className="Safety-sectionBody">
                    <div className="Safety-storyPart">
                        <div className="Safety-textImageWrapper">
                            <div className="Safety-storyPart--text">
                                <p>
                                    Camp Sarraka has been a fun, active, and creative place for children in Arlington since
                                    2013. Originally known as Camp SCAM (Sports, Crafts, And Memories), it was created in the
                                    summer of 2013 by Jake and Brody Karton, when they were in 8th and 6th grades, respectively.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Safety-subHeader Safety-subHeader--story">
                    Health During Camp
                </div>
                <div className="Safety-sectionBody">
                    <div className="Safety-storyPart">
                        <div className="Safety-textImageWrapper">
                            <div className="Safety-storyPart--text">
                                <p>
                                    Camp Sarraka has been a fun, active, and creative place for children in Arlington since
                                    2013. Originally known as Camp SCAM (Sports, Crafts, And Memories), it was created in the
                                    summer of 2013 by Jake and Brody Karton, when they were in 8th and 6th grades, respectively.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Safety-subHeader Safety-subHeader--story">
                    Insurance
                </div>
                <div className="Safety-sectionBody">
                    <div className="Safety-storyPart">
                        <div className="Safety-textImageWrapper">
                            <div className="Safety-storyPart--text">
                                <p>
                                    Camp Sarraka has been a fun, active, and creative place for children in Arlington since
                                    2013. Originally known as Camp SCAM (Sports, Crafts, And Memories), it was created in the
                                    summer of 2013 by Jake and Brody Karton, when they were in 8th and 6th grades, respectively.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Safety-subHeader Safety-subHeader--story">
                    Asthma
                </div>
                <div className="Safety-sectionBody">
                    <div className="Safety-storyPart">
                        <div className="Safety-textImageWrapper">
                            <div className="Safety-storyPart--text">
                                <p>
                                    Camp Sarraka has been a fun, active, and creative place for children in Arlington since
                                    2013. Originally known as Camp SCAM (Sports, Crafts, And Memories), it was created in the
                                    summer of 2013 by Jake and Brody Karton, when they were in 8th and 6th grades, respectively.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}