import React from "react";
import "./meetCounselors.scss";
import JakePic from "../../assets/images/meet_counselors_Jake.jpg";
import BrodyPic from "../../assets/images/meet_counselors_Brody.jpeg";
import EthanPic from "../../assets/images/meet_counselors_Ethan.jpg";
import DrewPic from "../../assets/images/meet_counselors_Drew.JPG";
import JadenPic from "../../assets/images/meet_counselors_Jaden.JPG";
import JaylanPic from "../../assets/images/meet_counselors_Jaylan.JPG";
import KaiPic from "../../assets/images/meet_counselors_Kai.jpg";
import TroyPic from "../../assets/images/meet_counselors_Troy.jpg";
import TurnerPic from "../../assets/images/meet_counselors_Turner.JPG";
import AdrianPic from "../../assets/images/meet_counselors_Adrian.jpg";
import JoePic from "../../assets/images/meet_counselors_Joe.jpg";
import DaphnePic from "../../assets/images/meet_counselors_Daphne.jpg";
import AdamPic from "../../assets/images/meet_counselors_Adam.jpg";

export function MeetCounselors() {
    return (
        <div className="Counselors">
            <div className="Counselors-header-container">
                <div className="Counselors-textWrapper">
                    <h3 className="Counselors-header">Meet the Team</h3>
                </div>
            </div>
            <img className="Counselors-background" alt="background"/>

            <div className="Counselors-content">
                <div className="Counselors-counselorWrapper">
                    <img className="Counselors-image" alt="headshot of counselor" src={JakePic} />
                    <div className="Counselors-name">Jake Karton</div>
                    <div className="Counselors-role">Co-Founder &amp; CEO</div>
                    <div className="Counselors-description">
                        Jake is a graduate of the University of Virginia where he studied finance and marketing in the McIntire School of Commerce and minored in math. After graduating in 2021, Jake joined Teach for America and taught Geometry at Colleton County High School in Walterboro, SC for two years. In 2017, Jake was named “Arlington Male Student Athlete of the Year” by the Better Sports Club of Arlington.

                        <div className="Counselors-list">
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite Sport:</div>
                                <div className="Counselors-answer">Ice Hockey</div>
                            </div>
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite Food:</div>
                                <div className="Counselors-answer">Chocolate Milk</div>
                            </div>
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite Animal:</div>
                                <div className="Counselors-answer">Siberian Tiger</div>
                            </div>
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite Quote:</div>
                                <div className="Counselors-answer">“No act of kindness, no matter how small, is ever wasted.” - Aesop</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Counselors-counselorWrapper">
                    <img className="Counselors-image" alt="headshot of counselor" src={BrodyPic} />
                    <div className="Counselors-name">Brody Karton</div>
                    <div className="Counselors-role">Co-Founder &amp; COO</div>
                    <div className="Counselors-description">
                        Brody graduated from Dartmouth College where he studied Economics,
Spanish, and Education. He earned a Fulbright ETA grant to work in Uruguay for the
2024 school year, with a focus on promoting cultural exchange through interactive play.
In the summer of 2022, he was named “Teaching Fellow of the Summer” at
Breakthrough Miami, an AmeriCorps-partnered nonprofit that provides academic-
enrichment to underrepresented students.

                        <div className="Counselors-list">
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite Animal:</div>
                                <div className="Counselors-answer">Hedgehog</div>
                            </div>
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite Food:</div>
                                <div className="Counselors-answer">Chicken Quesadilla</div>
                            </div>
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite School Subject:</div>
                                <div className="Counselors-answer">Science</div>
                            </div>
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite Quote:</div>
                                <div className="Counselors-answer">“Tell me and I forget. Teach me and I remember. Involve me and I learn." - Benjamin Franklin</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Counselors-counselorWrapper">
                    <img className="Counselors-image" alt="headshot of counselor" src={EthanPic} />
                    <div className="Counselors-name">Ethan Heckler</div>
                    <div className="Counselors-role">CFO &amp; Camp Director</div>
                    <div className="Counselors-description">
                        Ethan graduated from the University of Virginia with a major in Economics and Spanish. He walked on to the Varsity Baseball team for the fall 2017 season and studied abroad in Spain. Upon graduating in 2021, Ethan moved to Madison, Wisconsin to work as a Project Manager for the Healthcare IT company, Epic. Ethan spent six summers as a counselor and director at Burgundy Farms Summer Camp.

                        <div className="Counselors-list">
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite Food:</div>
                                <div className="Counselors-answer">Chicken Enchiladas</div>
                            </div>
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite Animal:</div>
                                <div className="Counselors-answer">Polar Bear</div>
                            </div>
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite Hobby:</div>
                                <div className="Counselors-answer">Juggling</div>
                            </div>
                            <div className="Counselors-listElement">
                                <div className="Counselors-question">Favorite Quote:</div>
                                <div className="Counselors-answer">“People will forget what you said, people will forget what you did, but
people will never forget how you made them feel.” - Maya Angelou</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Counselors-multiCounselorWrapper">
                    <div className="Counselors-multiCounselorWrapper-column1">
                        <div className="Counselors-multiCounselorWrapper-counselorWrapper">
                            <img className="Counselors-image-multi" alt="headshot of counselor" src={TroyPic} />
                            <div className="Counselors-name-multi">Troy Helton</div>
                            <div className="Counselors-role-multi">CHRO &amp; Director of Community Relations</div>
                            <div className="Counselors-description-multi">
                                <div className="Counselors-list">
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Sport:</div>
                                        <div className="Counselors-answer">Basketball</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite School Subject:</div>
                                        <div className="Counselors-answer">History</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Animal:</div>
                                        <div className="Counselors-answer">Lion</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Quote:</div>
                                        <div className="Counselors-answer">“The last thing you want to do is finish playing or doing anything and wish you would have worked harder.” – Derek Jeter</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Counselors-multiCounselorWrapper-counselorWrapper">
                            <img className="Counselors-image-multi" alt="headshot of counselor" src={DrewPic} />
                            <div className="Counselors-name-multi">Drew Sonn</div>
                            <div className="Counselors-role-multi">Counselor</div>
                            <div className="Counselors-description-multi">
                                <div className="Counselors-list">
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite School Subject:</div>
                                        <div className="Counselors-answer">History</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Food:</div>
                                        <div className="Counselors-answer">Tacos</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Animal:</div>
                                        <div className="Counselors-answer">Elephant</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Quote:</div>
                                        <div className="Counselors-answer">“Never let the fear of striking out keep you from playing the game.” – Babe Ruth</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Counselors-multiCounselorWrapper-counselorWrapper">
                            <img className="Counselors-image-multi" alt="headshot of counselor" src={KaiPic} />
                            <div className="Counselors-name-multi">Kai Bragg</div>
                            <div className="Counselors-role-multi">Counselor</div>
                            <div className="Counselors-description-multi">
                                <div className="Counselors-list">
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Sport:</div>
                                        <div className="Counselors-answer">Competitive Dance</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Food:</div>
                                        <div className="Counselors-answer">Veggie Pizza</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Animal:</div>
                                        <div className="Counselors-answer">Rusty Spotted Cat</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Quote:</div>
                                        <div className="Counselors-answer">“There’s a lot of hate in the world. But you need to remember that there’s also a lot of love. You choose which one is gonna lead your life.” – Jacqueline Bragg</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Counselors-multiCounselorWrapper-counselorWrapper">
                            <img className="Counselors-image-multi" alt="headshot of counselor" src={DaphnePic} />
                            <div className="Counselors-name-multi">Daphne Jeweler</div>
                            <div className="Counselors-role-multi">Counselor</div>
                            <div className="Counselors-description-multi">
                                <div className="Counselors-list">
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Sport:</div>
                                        <div className="Counselors-answer">Baseball</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite School Subject:</div>
                                        <div className="Counselors-answer">English</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Animal:</div>
                                        <div className="Counselors-answer">Dolphin</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Quote:</div>
                                        <div className="Counselors-answer">“Life is what we make it, always has been, always will be” - Grandma Moses</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Counselors-multiCounselorWrapper-counselorWrapper">
                            <img className="Counselors-image-multi" alt="headshot of counselor" src={AdrianPic} />
                            <div className="Counselors-name-multi">Adrian Tham</div>
                            <div className="Counselors-role-multi">Counselor</div>
                            <div className="Counselors-description-multi">
                                <div className="Counselors-list">
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Sport:</div>
                                        <div className="Counselors-answer">Basketball</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite School Subject:</div>
                                        <div className="Counselors-answer">Economics</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Animal:</div>
                                        <div className="Counselors-answer">Capybara</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Quote:</div>
                                        <div className="Counselors-answer">“Never give up, for that is just the place and time the tide will turn.” - Harriet Beecher Stowe</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Counselors-multiCounselorWrapper-column2">
                        <div className="Counselors-multiCounselorWrapper-counselorWrapper">
                            <img className="Counselors-image-multi" alt="headshot of counselor" src={TurnerPic} />
                            <div className="Counselors-name-multi">Matt Turner</div>
                            <div className="Counselors-role-multi">Lead Counselor</div>
                            <div className="Counselors-description-multi">
                                <div className="Counselors-list">
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Sport:</div>
                                        <div className="Counselors-answer">Basketball</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Food:</div>
                                        <div className="Counselors-answer">Pasta</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Animal:</div>
                                        <div className="Counselors-answer">Tiger</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Quote:</div>
                                        <div className="Counselors-answer">“You’ve got to stay patient, stay in the moment, and keep grinding. You never know what can happen.” – Tiger Woods</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Counselors-multiCounselorWrapper-counselorWrapper">
                            <img className="Counselors-image-multi" alt="headshot of counselor" src={JadenPic} />
                            <div className="Counselors-name-multi">Jaden Tham</div>
                            <div className="Counselors-role-multi">Counselor</div>
                            <div className="Counselors-description-multi">
                                <div className="Counselors-list">
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Sport:</div>
                                        <div className="Counselors-answer">Basketball</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Food:</div>
                                        <div className="Counselors-answer">Chicken Parm</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Animal:</div>
                                        <div className="Counselors-answer">Wolf</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Quote:</div>
                                        <div className="Counselors-answer">“Success isn’t about how much money you make; it’s about the difference you make in people’s lives.” – Michelle Obama</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Counselors-multiCounselorWrapper-counselorWrapper">
                            <img className="Counselors-image-multi" alt="headshot of counselor" src={JaylanPic} />
                            <div className="Counselors-name-multi">Jaylan Fall</div>
                            <div className="Counselors-role-multi">Counselor</div>
                            <div className="Counselors-description-multi">
                                <div className="Counselors-list">
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Food:</div>
                                        <div className="Counselors-answer">Steak and Eggs</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite School Subject:</div>
                                        <div className="Counselors-answer">Art</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Animal:</div>
                                        <div className="Counselors-answer">Buffalo</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Quote:</div>
                                        <div className="Counselors-answer">“Hard work beats talent when talent fails to work hard.” – Kevin Durant</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Counselors-multiCounselorWrapper-counselorWrapper">
                            <img className="Counselors-image-multi" alt="headshot of counselor" src={AdamPic} />
                            <div className="Counselors-name-multi">Adam Katz</div>
                            <div className="Counselors-role-multi">Counselor</div>
                            <div className="Counselors-description-multi">
                                <div className="Counselors-list">
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Sport:</div>
                                        <div className="Counselors-answer">Basketball</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite School Subject:</div>
                                        <div className="Counselors-answer">History</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Animal:</div>
                                        <div className="Counselors-answer">Wolf</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Quote:</div>
                                        <div className="Counselors-answer">“Be yourself; everyone else is already taken.” - Oscar Wilde</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Counselors-multiCounselorWrapper-counselorWrapper">
                            <img className="Counselors-image-multi" alt="headshot of counselor" src={JoePic} />
                            <div className="Counselors-name-multi">Joe Watkins</div>
                            <div className="Counselors-role-multi">Counselor</div>
                            <div className="Counselors-description-multi">
                                <div className="Counselors-list">
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Sport:</div>
                                        <div className="Counselors-answer">Basketball</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite School Subject:</div>
                                        <div className="Counselors-answer">Math</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Animal:</div>
                                        <div className="Counselors-answer">Gazelle</div>
                                    </div>
                                    <div className="Counselors-listElement">
                                        <div className="Counselors-question">Favorite Quote:</div>
                                        <div className="Counselors-answer">“I really think a champion is defined not by their wins but by how they can recover when they fall.” - Serena Williams</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}